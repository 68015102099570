import { Mark, mergeAttributes } from "@tiptap/core";

import {
  LINEBREAK_CLASS_NAME,
  LINEBREAK_MARK_NAME,
} from "@/models/papyrusText";

export const TipTapLineBreakMark = Mark.create({
  name: LINEBREAK_MARK_NAME,

  addOptions: {
    // @ts-expect-error vendor-specific
    HTMLAttributes: {},
  },

  parseHTML() {
    return [
      {
        tag: `span.${LINEBREAK_CLASS_NAME}`,
        getAttrs: (node) => (node instanceof HTMLElement ? {} : false),
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      "span",
      mergeAttributes({ class: LINEBREAK_CLASS_NAME }, HTMLAttributes),
      0,
    ];
  },
});
