<script setup lang="ts">
import { ref } from "vue";
import Button from "primevue/button";
import InputNumber from "primevue/inputnumber";
import { useAdminStore } from "@/stores/useAdminStore";
import { eventBus } from "@/services/EventBus";
import DialogWindowLayout from "./DialogWindowLayout.vue";

const adminStore = useAdminStore();
const handshiftContent = ref(1);

const onSave = async () => {
  if (!handshiftContent.value) {
    adminStore.data.message = {
      type: "error",
      content: "Handshift No. field is required",
    };
    return false;
  } else {
    eventBus.emit("insertHandshift", {
      handshiftContent: String(handshiftContent.value),
    });
    onCancel();
  }
};

const onCancel = () => {
  adminStore.closeDialog();
};
</script>
<template>
  <DialogWindowLayout>
    <div class="modal-window-input-field modal-window-input-field-handshift">
      <div class="modal-window-input-field-label">Handshift No. *</div>
      <InputNumber
        id="handhshift-content"
        v-model="handshiftContent"
        :min="1"
        :max="1000"
        :use-grouping="false"
        required="true" />
    </div>

    <template #footer>
      <Button
        label="Cancel"
        icon="pi pi-times"
        severity="info"
        @click="onCancel" />
      <Button
        label="OK"
        severity="success"
        icon="pi pi-check"
        @click="onSave" />
    </template>
  </DialogWindowLayout>
</template>
<style lang="scss">
@import "@/assets/variables.scss";
</style>
