<script setup lang="ts">
import { useApiStore } from "@/stores/useApiStore";

const apiStore = useApiStore();
</script>

<template>
  <p
    class="home-welcome-text"
    v-html="apiStore.data.editorialContent.welcome_text" />
</template>

<style lang="scss">
@import "@/assets/variables.scss";

.home-welcome-text {
  color: $text-color;
  max-width: $text-max-width;
  text-align: center;
}
</style>
