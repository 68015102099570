<script setup lang="ts">
import Button from "primevue/button";
import { useRouter } from "vue-router";
import { useToast } from "primevue/usetoast";

import { TOAST_TIMEOUT } from "@/services/Helpers";
import { AdminApi } from "@/services/AdminApi";
import { useAppStore } from "@/stores/useAppStore";
import { useApiStore } from "@/stores/useApiStore";

const toast = useToast();
const router = useRouter();
const appStore = useAppStore();
const apiStore = useApiStore();

const onCopyText = (type: string) => {
  const papyrus = apiStore.data.papyri.find(
    (item) => Number(item.id) === Number(appStore.data.selectedPapyrusId),
  );
  if (!papyrus) {
    console.error("Papyrus not found");
    return false;
  }
  const text = type === "original" ? papyrus?.greekText : papyrus?.englishText;
  const plainText = text.replace(/<[^>]*>/g, "");
  navigator.clipboard
    .writeText(plainText)
    .then(() => {
      toast.add({
        severity: "success",
        summary: `Success`,
        detail: `${type.charAt(0).toUpperCase()}${type.slice(1)} text copied to clipboard.`,
        life: TOAST_TIMEOUT,
      });
    })
    .catch((err) => {
      console.error("Failed to copy text to clipboard: ", err);
    });
};
</script>

<template>
  <div class="papyrus-text-toolbar">
    <Button
      v-if="AdminApi.isLoggedIn() && appStore.data.selectedPapyrusId"
      type="button"
      severity="info"
      class="admin-edit-button"
      label="Edit papyrus text"
      icon="pi pi-file-edit"
      @click="
        router.push(
          `/admin/annotation-editor/${appStore.data.selectedPapyrusId}`,
        )
      " />
    <Button
      type="button"
      severity="error"
      class="copy-to-clipboard-button"
      title="Copy original text to clipboard"
      label="Copy original"
      icon="pi pi-clipboard"
      @click="onCopyText('original')" />
    <Button
      type="button"
      severity="error"
      class="copy-to-clipboard-button"
      title="Copy translation text to clipboard"
      label="Copy translation"
      icon="pi pi-clipboard"
      @click="onCopyText('translation')" />
  </div>
</template>

<style lang="scss">
@import "@/assets/variables.scss";
.papyrus-text-toolbar {
  display: flex;
  justify-content: flex-end;
  margin-top: 64px;
  column-gap: 8px;
}
.copy-to-clipboard-button {
  @include archeion-rounded-button;
}
.admin-edit-button {
  @include archeion-rounded-button;
}
</style>
