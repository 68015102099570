import { Mark, markInputRule } from "@tiptap/core";

import {
  LINEBREAK_CLASS_NAME,
  LINEBREAK_MARK_NAME,
  LINEBREAK_EXTENSION_NAME,
} from "@/models/papyrusText";

export const TipTapLineBreak = Mark.create({
  name: LINEBREAK_EXTENSION_NAME,

  parseHTML() {
    return [
      {
        tag: `span.${LINEBREAK_MARK_NAME}`,
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ["span", { ...HTMLAttributes, class: LINEBREAK_CLASS_NAME }, 0];
  },

  addInputRules() {
    return [
      markInputRule({
        find: /\(line (\d+)\)/,
        type: this.type,
      }),
    ];
  },
});
