import { Mark, mergeAttributes } from "@tiptap/core";
import {
  ANNOTATION_CLASS_NAME,
  ANNOTATION_CONTENT_IDENTIFIER,
  ANNOTATION_INTERNAL_IDENTIFIER,
  ANNOTATION_MARK_NAME,
} from "@/models/papyrusText";

export const TipTapAnnotationMark = Mark.create({
  name: ANNOTATION_MARK_NAME,

  addOptions: {
    // @ts-expect-error vendor-specific
    HTMLAttributes: {},
  },

  parseHTML() {
    return [
      {
        tag: `span.${ANNOTATION_CLASS_NAME}`,
        getAttrs: (node) => (node instanceof HTMLElement ? {} : false),
      },
    ];
  },

  // This function controls how the mark gets rendered to HTML
  renderHTML({ HTMLAttributes }) {
    return [
      "span",
      mergeAttributes({ class: ANNOTATION_CLASS_NAME }, HTMLAttributes),
      0,
    ];
  },

  addAttributes() {
    return {
      [ANNOTATION_CONTENT_IDENTIFIER]: {
        default: null,
        parseHTML: (element) =>
          element.getAttribute(ANNOTATION_CONTENT_IDENTIFIER),
        renderHTML: (attributes) => {
          if (!attributes[ANNOTATION_CONTENT_IDENTIFIER]) {
            return {};
          }
          return {
            [ANNOTATION_CONTENT_IDENTIFIER]:
              attributes[ANNOTATION_CONTENT_IDENTIFIER],
          };
        },
      },
      [ANNOTATION_INTERNAL_IDENTIFIER]: {
        default: null,
        parseHTML: (element) =>
          element.getAttribute(ANNOTATION_INTERNAL_IDENTIFIER),
        renderHTML: (attributes) => {
          if (!attributes[ANNOTATION_INTERNAL_IDENTIFIER]) {
            return {};
          }
          return {
            [ANNOTATION_INTERNAL_IDENTIFIER]:
              attributes[ANNOTATION_INTERNAL_IDENTIFIER],
          };
        },
      },
    };
  },
});
