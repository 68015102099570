<script setup lang="ts">
import { ref } from "vue";
import type { AnnotatedTextProps } from "@/models/papyrusText";
import type { TextType } from "@/models/papyrusText";
import { useApiStore } from "@/stores/useApiStore";
import { useAppStore } from "@/stores/useAppStore";
const props: AnnotatedTextProps = defineProps<AnnotatedTextProps>();

const apiStore = useApiStore();
const appStore = useAppStore();
const annotation = apiStore.data.annotations[Number(props.annotationId)];
const annotationContent =
  annotation && annotation.content ? annotation.content : "";
const spanRef = ref<HTMLElement | null>(null);

const getCssClassString = (annotationId: number) => {
  let classes = ["papyrus-text-highlight"];

  if (appStore.data.papyrusText.selectedAnnotationId === annotationId) {
    classes.push("papyrus-text-highlight--selected");
  }

  return classes.join(" ");
};

const handleClick = () => {
  appStore.openPapyrusModal(
    annotationContent,
    Number(props.annotationId),
    annotation.type as TextType,
  );

  spanRef.value?.scrollIntoView({
    behavior: "smooth",
    block: "start",
    inline: "nearest",
  });
  const SCROLL_OFFSET = 40;
  const elementPosition = spanRef.value?.getBoundingClientRect().top;
  if (elementPosition) {
    const offsetPosition = window.scrollY + elementPosition - SCROLL_OFFSET;
    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  }
};

const setHovered = (element: HTMLElement, isHover = true) => {
  const groupId = element.getAttribute("data-annotation-group-id");
  const groupMembers = document.querySelectorAll(
    `.papyrus-text-highlight[data-annotation-group-id="${groupId}"]`,
  );
  groupMembers.forEach((member) => {
    if (isHover) {
      member.classList.add("papyrus-text-hover");
    } else {
      member.classList.remove("papyrus-text-hover");
    }
  });
};

const handleMouseOver = (event: MouseEvent) => {
  setHovered(event.target as HTMLElement, true);
};
const handleMouseLeave = (event: MouseEvent) => {
  setHovered(event.target as HTMLElement, false);
};
</script>

<template>
  <span
    v-if="annotation"
    ref="spanRef"
    @mouseover="handleMouseOver"
    @mouseleave="handleMouseLeave"
    @click.stop="handleClick()"
    v-html="props.annotatedText"
    :data-annotation-id="props.annotationId"
    :data-annotation-group-id="`${props.annotationId}-${props.annotationGroupId}`"
    :class="getCssClassString(props.annotationId)"></span>
  <!-- If annotation is missing, display text without annotation -->
  <span v-else v-html="props.annotatedText"> </span>
</template>

<style lang="scss">
@import "@/assets/variables.scss";
.papyrus-text-highlight {
  cursor: pointer;
  border-bottom: 2px solid $glossary-border-color;
}
.papyrus-text-highlight--selected {
  background-color: $glossary-background-color;
}

.papyrus-text-col-translation {
  .papyrus-text-hover {
    background-color: $glossary-background-color;
    transition: background-color 0.35s;
  }
}

.papyrus-text-col-original {
  .papyrus-text-hover {
    background-color: $apparatus-background-color;
    transition: background-color 0.35s;
  }
  .papyrus-text-highlight {
    cursor: pointer;
    border-bottom: 2px solid $apparatus-border-color;
  }
  .papyrus-text-highlight--selected {
    background-color: $apparatus-background-color;
  }
}
</style>
