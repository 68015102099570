<script setup lang="ts">
import { useRouter } from "vue-router";
import { ROUTES } from "@/models/routes";

const router = useRouter();
const onClick = (event: MouseEvent) => {
  event.preventDefault();
  router.push(`${ROUTES.LITERATURE.path}`);
};
</script>

<template>
  <div class="bibliography-link">
    <a @click="onClick" href="#">View bibliography</a>
  </div>
</template>

<style lang="scss">
@import "@/assets/variables.scss";
.bibliography-link {
  margin-top: 12px;
}
</style>
