<script setup lang="ts">
import { watch, ref, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";

import type { TabViewChangeEvent } from "primevue/tabview";
import { useToast } from "primevue/usetoast";
import Toast from "primevue/toast";
import Divider from "primevue/divider";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";

import { ROUTES } from "@/models/routes";
import PageLayout from "@/components/PageLayout.vue";
import ProgressSpinner from "@/components/LoadingState/ProgressSpinner.vue";
import PapyrusEditor from "@/views/Admin/AnnotationEditor/PapyrusEditor.vue";
import EditorHelp from "@/views/Admin/AnnotationEditor/EditorHelp.vue";
import PapyrusSelector from "@/components/PapyrusSelector.vue";
import DialogWindow from "@/views/Admin/AnnotationEditor/DialogWindow/DialogWindow.vue";
import AnnotationListView from "@/views/Admin/AnnotationList/AnnotationListView.vue";
import { useAdminStore } from "@/stores/useAdminStore";
import { TOAST_TIMEOUT } from "@/services/Helpers";
import type { ToastMessage } from "@/models/admin";

const router = useRouter();
const route = useRoute();
const adminStore = useAdminStore();
const toast = useToast();
const activeTabIndex = ref(0);
const isLoading = ref(true);

const papyrusSlug = route.params.id ? "/" + route.params.id : "";
const tabIndexToRoute: Record<string, string> = {
  0: ROUTES.ANNOTATION_EDITOR.path + papyrusSlug,
  1: ROUTES.ANNOTATION_MANAGER.path + papyrusSlug,
  2: ROUTES.ANNOTATION_HELP.path + papyrusSlug,
};

onMounted(async () => {
  await onLoad();
});

const onLoad = async () => {
  isLoading.value = true;
  await adminStore.fetchAnnotations();
  await adminStore.fetchPapyri();
  await new Promise((resolve) => setTimeout(resolve, 1500));
  adminStore.data.selectedPapyrusId = route.params.id
    ? Number(route.params.id)
    : 0;
  isLoading.value = false;
};

// Toast Service
watch(
  () => adminStore.data.message,
  (message: ToastMessage) => {
    if (message) {
      toast.add({
        severity: message.type,
        summary: message.type.charAt(0).toUpperCase() + message.type.slice(1),
        detail: message.content,
        life: TOAST_TIMEOUT,
      });
    }
  },
);
watch(
  () => route.params.id,
  () => {
    window.location.reload();
  },
);

watch(
  route,
  (newRoute) => {
    const path = newRoute.path;
    const tabIndex = Object.keys(tabIndexToRoute).find(
      (key: string) => tabIndexToRoute[key] === path,
    );
    activeTabIndex.value = tabIndex ? parseInt(tabIndex) : 0;
    // prevent open dialog if user hits back button in browser
    adminStore.closeDialog();
  },
  { immediate: true },
);

const onTabChange = (activeTab: TabViewChangeEvent) => {
  const path = tabIndexToRoute[activeTab.index];
  if (path) {
    router.push(path);
  }
  onLoad();
};
const onSelect = (papyrusId: number) => {
  window.location.href = ROUTES.ANNOTATION_EDITOR.path + "/" + papyrusId;
};
</script>

<template>
  <PageLayout :isContentPage="true" :isAdminLayout="true">
    <template #default>
      <div class="admin-container-box" v-if="isLoading">
        <ProgressSpinner />
      </div>
      <div v-if="!isLoading" class="admin-container">
        <TabView
          :lazy="true"
          v-model:activeIndex="activeTabIndex"
          @tab-change="onTabChange">
          <TabPanel header="Text Editor">
            <PapyrusSelector
              :selectedPapyrusId="adminStore.data.selectedPapyrusId"
              style="margin-top: 12px"
              :onSelect="onSelect"
              :optionsData="adminStore.data.papyri"
              :isWide="true" />
            <Divider class="admin-editor-divider"></Divider>
            <PapyrusEditor v-if="adminStore.data.selectedPapyrusId" />
          </TabPanel>
          <TabPanel header="Annotation Manager">
            <AnnotationListView />
          </TabPanel>
          <TabPanel header="Help">
            <EditorHelp />
          </TabPanel>
        </TabView>
      </div>
    </template>
  </PageLayout>
  <Toast></Toast> <DialogWindow></DialogWindow>
</template>

<style lang="scss">
@import "@/assets/variables.scss";

.admin-container-box {
  min-height: 600px;
}
.admin-container {
  .editor-navigation {
    display: flex;
    justify-content: flex-end;
  }
  .archeion-linebreak,
  .archeion-handshift {
    @include medium-normal;
    border-radius: 100vw;
    padding: 1px 4px 1px 4px;
    margin: 0px 2px 0px 2px;
  }
  .archeion-linebreak {
    border: 1px solid #2196f3;
    color: #2196f3;
  }
  .archeion-handshift {
    border: 1px solid $archeion-yellow;
    color: $archeion-yellow;
  }
  .admin-editor-divider {
    padding-top: 12px !important;
  }
}
</style>
