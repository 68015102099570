<script setup lang="ts">
import { useRouter } from "vue-router";
import { ROUTES } from "@/models/routes";

const router = useRouter();
</script>

<template>
  <div class="footer">
    <div class="footer-items">
      <div class="footer-item footer-item-1">© University of Zurich</div>
      <div class="footer-item footer-item-2">
        <a
          class="footer-item-router-link"
          :class="{
            'footer-item-router-link-active':
              router.currentRoute.value.path === ROUTES.TERMS.path,
          }"
          :href="ROUTES.TERMS.path"
          @click.prevent="router.push(ROUTES.TERMS.path)"
          >Terms and conditions</a
        >
      </div>
      <div class="footer-item footer-item-3">
        <a href="https://www.uzh.ch" target="_blank">
          <img
            alt="UZH Logo"
            class="footer-logo"
            src="@/assets/images/uzh_logo.svg"
        /></a>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import "@/assets/variables.scss";

.footer {
  margin: 12px;
  background-color: rgb(255, 255, 255, 0.9);
  border: 1px solid $container-divider-color;
  border-radius: 16px;
  box-shadow: $box-shadow;
}
.footer-items {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 8px 16px 8px 16px;
}
.footer-item {
  @include small-normal;
}
.footer-item-1,
.footer-item-3 {
  flex: 1;
}
.footer-item-1 {
  text-align: left;
}
.footer-item-2 {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  min-width: 33%;
}

.footer-item-3 {
  text-align: right;
}

@media (max-width: $phone-breakpoint) {
  .footer-items {
    display: block;
    text-align: center;
  }

  .footer-item-1,
  .footer-item-2,
  .footer-item-3 {
    position: static;
    transform: none;
    min-width: 100%;
    text-align: center;
    margin: 8px 0;
  }
}

.footer-logo {
  width: 100px;
  background-color: black;
}
.footer-logo {
  transition: background-color 0.3s ease;
  mask-image: url("@/assets/images/uzh_logo.svg");
  mask-repeat: no-repeat;
  mask-size: contain;
}

.footer-logo:hover {
  opacity: 0.6;
}

.footer-item-router-link {
  color: $text-color;
  @include small-normal;
  &:hover {
    color: $archeion-red;
  }
}
.footer-item-router-link-active {
  color: $archeion-red;
  text-decoration: underline;
}
</style>
