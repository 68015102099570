<script setup lang="ts">
import { onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import Divider from "primevue/divider";
import Toast from "primevue/toast";

import { useApiStore } from "@/stores/useApiStore";
import PageLayout from "@/components/PageLayout.vue";
import PapyrusHeader from "@/views/Papyrus/PapyrusHeader.vue";
import PapyrusToolbar from "@/views/Papyrus/PapyrusToolbar.vue";
import PapyrusMetadata from "@/views/Papyrus/PapyrusMetadata.vue";
import PapyrusDataSection from "@/views/Papyrus/PapyrusDataSection.vue";
import PapyrusAnnotatedText from "@/views/Papyrus/PapyrusAnnotatedText.vue";
import { useAppStore } from "@/stores/useAppStore";
import { ROUTES } from "@/models/routes";
import type { Papyrus } from "@/models/strapi";

const apiStore = useApiStore();
const appStore = useAppStore();
const route = useRoute();
const router = useRouter();

const isPapyrusNotFound = ref(false);
const papyrus = ref<Papyrus>();

onMounted(async () => {
  await apiStore.initialLoad();
  if (route.params.id) {
    appStore.data.selectedPapyrusId = Number(route.params.id);
    selectPapyrus();
  } else {
    if (appStore.data.selectedPapyrusId) {
      router.push(`${ROUTES.PAPYRUS.path}/${appStore.data.selectedPapyrusId}`);
      selectPapyrus();
    }
  }

  if (route.query.annotationId) {
    await new Promise((resolve) => setTimeout(resolve, 10));
    openAnnotation(Number(route.query.annotationId));
  }
});

watch(
  () => route.params.id,
  () => {
    appStore.data.selectedPapyrusId = Number(route.params.id);
  },
);
watch(
  () => route.query.annotationId,
  () => {
    openAnnotation(Number(route.query.annotationId));
  },
);
watch(
  () => appStore.data.selectedPapyrusId,
  () => {
    selectPapyrus();
  },
);
const openAnnotation = (annotatioId: number) => {
  const annotationElement = window.document.querySelector(
    `[data-annotation-id="${annotatioId}"]`,
  ) as HTMLElement | null;
  if (annotationElement) {
    annotationElement.click();
  } else {
    console.error("annotationElement was not found");
  }
};

const selectPapyrus = () => {
  const result = apiStore.data.papyri.find(
    (item: Papyrus) => item.id === appStore.data.selectedPapyrusId,
  );
  if (result) {
    papyrus.value = result;
    isPapyrusNotFound.value = false;
  } else {
    isPapyrusNotFound.value = true;
  }
};
</script>

<template>
  <PageLayout :isContentPage="true">
    <template #default>
      <PapyrusHeader
        :papyri="apiStore.data.papyri"
        :papyrus="papyrus"
        :selectedPapyrusId="appStore.data.selectedPapyrusId" />
      <div
        class="papyrus-header-not-found"
        v-if="isPapyrusNotFound || appStore.data.selectedPapyrusId <= 0">
        Please select a papyrus.
      </div>

      <span v-if="appStore.data.selectedPapyrusId">
        <Divider class="papyrus-header-divider" />
        <div class="papyrus-header-text">{{ papyrus?.title }}</div>
        <PapyrusToolbar :papyrus="papyrus" />
        <PapyrusMetadata :papyrus="papyrus" />
        <PapyrusDataSection :papyrus="papyrus" />
        <Divider />
        <PapyrusAnnotatedText :papyrus="papyrus"
      /></span>
    </template>
  </PageLayout>
  <Toast></Toast>
</template>

<style lang="scss">
@import "@/assets/variables.scss";

.papyrus-section-heading {
  @include medium-bold;
  color: $text-color;
}
.papyrus-header-not-found {
  padding-top: 24px;
}
.papyrus-header-divider {
  margin: 24px 0 0 0 !important;
}
</style>
