import { Extension } from "@tiptap/core";
import { Plugin } from "prosemirror-state";
import { LINEBREAK_EXTENSION_NAME } from "@/models/papyrusText";

export const TiptapPastePlainText = Extension.create({
  name: "pastePlainText",

  addProseMirrorPlugins() {
    return [
      new Plugin({
        props: {
          handlePaste: (view, event: ClipboardEvent) => {
            let text = event.clipboardData?.getData("text/plain") || "";

            // Search for the pattern: |number(0-999) followed by a space
            const pattern = /\|(\d{1,3})\s/g;
            const matches = [...text.matchAll(pattern)];

            let tr = view.state.tr;
            const { from } = tr.selection;

            // Remove pipe symbols from the text
            text = text.replace(/\|/g, "");

            // Replace the current selection with the modified text
            tr = tr.replaceSelectionWith(view.state.schema.text(text));

            // Apply marks for each match
            if (matches.length > 0) {
              matches.forEach((match) => {
                const lineNumber = match[1];
                const startPos = text.indexOf(lineNumber + " ");
                if (startPos !== -1) {
                  const markFrom = from + startPos;
                  const markTo = markFrom + lineNumber.length;

                  tr = tr.addMark(
                    markFrom,
                    markTo,
                    view.state.schema.marks[LINEBREAK_EXTENSION_NAME].create({
                      lineNumber,
                    }),
                  );
                }
              });
            }
            view.dispatch(tr);

            // Prevents the default paste behavior
            return true;
          },
        },
      }),
    ];
  },
});
