import axios from "axios";
import { AxiosError } from "axios";

import {
  LOCAL_STORAGE_JWT,
  LOCAL_STORAGE_JWT_EXPIRY,
  LOCAL_STORAGE_ADMIN_USERNAME,
  getBaseStrapiUrl,
} from "@/services/Helpers";
import type {
  StrapiPapyrus,
  StrapiResultSingle,
  StrapiUser,
  AnnotationContentStrapi,
} from "@/models/strapi";
import type { PapyrusDataPayload } from "@/models/admin";
import { ENDPOINTS } from "@/services/ApiEndpoints";

const BASE_URL = getBaseStrapiUrl();
const JWT_TOKEN_EXPIRY = 1000 * 60 * 60 * 24 * 7; // 1 week

const axiosInstance = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

export const AdminApi = {
  isLoggedIn: (): boolean => {
    const accessToken = localStorage.getItem(LOCAL_STORAGE_JWT);
    const expiry = localStorage.getItem(LOCAL_STORAGE_JWT_EXPIRY);
    const now = Date.now();

    if (accessToken && expiry && now - parseInt(expiry) < JWT_TOKEN_EXPIRY) {
      axiosInstance.defaults.headers.Authorization = `Bearer ${accessToken}`;
      return true;
    } else {
      //console.error("Expired or accessToken absent", accessToken);
      localStorage.removeItem(LOCAL_STORAGE_JWT);
      localStorage.removeItem(LOCAL_STORAGE_JWT_EXPIRY);
      localStorage.removeItem(LOCAL_STORAGE_ADMIN_USERNAME);
      delete axiosInstance.defaults.headers.Authorization;
      return false;
    }
  },

  login: async (
    username: string,
    password: string,
  ): Promise<StrapiUser | undefined | null> => {
    delete axiosInstance.defaults.headers.Authorization;
    try {
      const response = await axiosInstance.post(ENDPOINTS.login, {
        identifier: username,
        password: password,
      });
      if (response && response.data.jwt) {
        const accessToken = response.data.jwt;
        localStorage.setItem(LOCAL_STORAGE_JWT, accessToken);
        localStorage.setItem(LOCAL_STORAGE_JWT_EXPIRY, Date.now().toString());
        localStorage.setItem(LOCAL_STORAGE_ADMIN_USERNAME, username);
        axiosInstance.defaults.headers.Authorization = `Bearer ${accessToken}`;

        return response.data;
      }
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        console.error("Login error:", error.response?.data.error);
      }
      return null;
    }
  },
  logout: () => {
    localStorage.removeItem(LOCAL_STORAGE_JWT);
    localStorage.removeItem(LOCAL_STORAGE_ADMIN_USERNAME);
    delete axiosInstance.defaults.headers.Authorization;
  },
  readPapyrus: async (
    id: number,
  ): Promise<StrapiResultSingle<StrapiPapyrus> | null> => {
    try {
      const response = await axiosInstance.get(ENDPOINTS.papyrus + "/" + id);
      return response.data;
    } catch (error) {
      return null;
    }
  },
  updatePapyrus: async (id: number, payload: PapyrusDataPayload) => {
    const history = {
      papyrusLastEditedBy: `${localStorage.getItem(LOCAL_STORAGE_ADMIN_USERNAME)} 
       | ${new Date(Date.now()).toLocaleString("de-DE")}`,
    };
    const response = await axiosInstance.put(ENDPOINTS.papyrus + "/" + id, {
      data: { ...payload, ...history },
    });
    return response.data;
  },
  updatePapyrusNotes: async (id: number, content: string) => {
    const response = await axiosInstance.put(ENDPOINTS.papyrus + "/" + id, {
      data: { papyrusNotes: content },
    });
    return response.data;
  },

  updatePapyrusLastEditedBy: async (id: number, content: string) => {
    const response = await axiosInstance.put(ENDPOINTS.papyrus + "/" + id, {
      data: { papyrusLastEditedBy: content },
    });
    return response.data;
  },

  createAnnotation: async (payload: AnnotationContentStrapi) => {
    const response = await axiosInstance.post(ENDPOINTS.annotation, {
      data: payload,
    });
    return response.data;
  },
  updateAnnotation: async (id: number, payload: AnnotationContentStrapi) => {
    const response = await axiosInstance.put(ENDPOINTS.annotation + "/" + id, {
      data: payload,
    });
    return response.data;
  },
  deleteAnnotation: async (id: number) => {
    const response = await axiosInstance.delete(
      ENDPOINTS.annotation + "/" + id,
    );
    return response.data;
  },
};
