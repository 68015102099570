import type { Editor } from "@tiptap/vue-3";
import {
  ANNOTATION_CONTENT_IDENTIFIER,
  ANNOTATION_CLASS_NAME,
} from "@/models/papyrusText";

import type { AnnotatedTextProps } from "@/models/papyrusText";

const HOVER_CLASS = "archeion-annotation-hover";
let container: HTMLElement;

export const highlightGroupedAnnotations = (htmlElement: HTMLElement): void => {
  container = htmlElement;

  Array.from(container.getElementsByClassName(ANNOTATION_CLASS_NAME)).forEach(
    (span: Node) => {
      span.removeEventListener("mouseover", handleMouseOver as EventListener);
      span.removeEventListener("mouseleave", handleMouseLeave as EventListener);
    },
  );

  Array.from(container.getElementsByClassName(ANNOTATION_CLASS_NAME)).forEach(
    (span: Node) => {
      span.addEventListener("mouseover", handleMouseOver as EventListener);
      span.addEventListener("mouseleave", handleMouseLeave as EventListener);
    },
  );
};

const handleMouseOver = (event: MouseEvent): void => {
  event.stopPropagation();
  const annotationId = (event.target as HTMLElement)?.getAttribute(
    ANNOTATION_CONTENT_IDENTIFIER,
  );
  const foundAnnos = Array.from(
    container.getElementsByClassName(ANNOTATION_CLASS_NAME),
  ).filter(
    (span) => span.getAttribute(ANNOTATION_CONTENT_IDENTIFIER) === annotationId,
  );

  foundAnnos.forEach(async (span) => {
    // needs timeout, otherwise DOM breaks
    await new Promise((resolve) => setTimeout(resolve, 10));
    span.classList.add(HOVER_CLASS);
  });
};

const handleMouseLeave = (event: MouseEvent): void => {
  event.stopPropagation();
  const annotationId = (event.target as HTMLElement)?.getAttribute(
    ANNOTATION_CONTENT_IDENTIFIER,
  );
  Array.from(container.getElementsByClassName(ANNOTATION_CLASS_NAME))
    .filter(
      (span) =>
        span.getAttribute(ANNOTATION_CONTENT_IDENTIFIER) === annotationId,
    )
    .forEach((span) => {
      setTimeout(() => {
        span.classList.remove(HOVER_CLASS);
      }, 10);
    });
};

// note: an annotation grouping is only added for each newAnnotationId
export const updateAnnotationsInEditor = (
  editor: Editor,
  oldAnnotationId: number,
  newAnnotationId: number,
) => {
  const html = editor.getHTML();
  const updatedHtml = html.replace(
    new RegExp(`data-annotation-id="${oldAnnotationId}"`, "g"),
    `data-annotation-id="${newAnnotationId}"`,
  );
  return updatedHtml;
};

export const extractAnnotationsFromHtml = (
  htmlContent: string,
): AnnotatedTextProps[] => {
  const result: AnnotatedTextProps[] = [];
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlContent, "text/html");
  const annotations = doc.querySelectorAll(`.${ANNOTATION_CLASS_NAME}`);
  const annotationGroupMap: { [key: number]: number } = {};
  let currentGroupId = 1;

  Array.from(annotations).forEach((annotation) => {
    const annotationId = annotation.getAttribute(ANNOTATION_CONTENT_IDENTIFIER);
    if (annotationId) {
      const parsedAnnotationId = Number(annotationId);
      if (!annotationGroupMap[parsedAnnotationId]) {
        annotationGroupMap[parsedAnnotationId] = currentGroupId++;
      }
      result.push({
        annotationId: parsedAnnotationId,
        annotatedText: annotation.innerHTML,
        annotationGroupId: annotationGroupMap[parsedAnnotationId],
      });
    }
  });

  return result;
};
