<script setup lang="ts">
import type { DefaultTextProps } from "@/models/papyrusText";
import { HANDSHIFT_CLASS_NAME } from "@/models/papyrusText";
const props: DefaultTextProps = defineProps<DefaultTextProps>();
</script>

<template>
  <span title="Handshift" :class="HANDSHIFT_CLASS_NAME"
    >H{{ props.content }}
  </span>
</template>
