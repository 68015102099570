<script setup lang="ts">
import { useAdminStore } from "@/stores/useAdminStore";
import EditApparatus from "./EditApparatus.vue";
import EditGlossary from "./EditGlossary.vue";
import InsertLineBreak from "./InsertLineBreak.vue";
import InsertHandshift from "./InsertHandshift.vue";
import ListEditAnnotation from "./ListEditAnnotation.vue";
import EditPapyrusTextNotes from "./EditPapyrusTextNotes.vue";

const adminStore = useAdminStore();
</script>
<template>
  <div v-if="adminStore.data.dialog.type !== 'closed'">
    <EditApparatus
      v-if="adminStore.data.dialog.type === 'add-apparatus'"
      :is-update="false" />
    <EditApparatus
      v-if="adminStore.data.dialog.type === 'update-apparatus'"
      :is-update="true" />
    <EditGlossary
      v-if="adminStore.data.dialog.type === 'add-glossary'"
      :is-update="false" />
    <EditGlossary
      v-if="adminStore.data.dialog.type === 'update-glossary'"
      :is-update="true" />
    <InsertLineBreak
      v-if="adminStore.data.dialog.type === 'insert-linebreak'" />
    <InsertHandshift
      v-if="adminStore.data.dialog.type === 'insert-handshift'" />
    <ListEditAnnotation
      v-if="adminStore.data.dialog.type === 'list-edit-annotation'"
      :is-update="true" />
    <EditPapyrusTextNotes
      v-if="adminStore.data.dialog.type === 'edit-papyrus-text-notes'" />
  </div>
</template>
<style lang="scss">
@import "@/assets/variables.scss";
</style>
