<script setup lang="ts">
import { ref, computed, type Ref, type Component } from "vue";
import PapyrusDataSectionLiteratureLink from "@/views/Papyrus/PapyrusDataSectionLiteratureLink.vue";
import type { Papyrus } from "@/models/strapi";

const props = defineProps<{
  papyrus: Papyrus | undefined;
}>();

type SectionWithRef = {
  title: string;
  content: string;
  isExpanded: Ref<boolean>;
  // short texts do not need a show more button
  textRef: Ref<HTMLElement>;
  additionalContent: Component | null;
};

const papyrusSections: Ref<SectionWithRef[]> = computed(() => {
  if (!props.papyrus) return [];
  const sections: Array<SectionWithRef> = [
    {
      title: "Description",
      content: props.papyrus.description ?? "",
      isExpanded: ref(false),
      textRef: ref(),
      additionalContent: null,
    },
    {
      title: "Literature",
      content: props.papyrus.literature ?? "",
      isExpanded: ref(false),
      textRef: ref(),
      additionalContent: PapyrusDataSectionLiteratureLink,
    },
  ].filter((section) => section.content);
  return sections;
});

const onClickShowMore = (section: SectionWithRef) => {
  section.isExpanded.value = !section.isExpanded.value;
};
// needed to show text with ellipsis in collapsed mode
const stripHtmlTags = (html: string): string => {
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = html;
  return tempDiv.innerText;
};
</script>

<template>
  <div class="papyrus-data-sections">
    <div
      v-for="section in papyrusSections"
      :key="section.title"
      :class="`papyrus-data-section papyrus-data-section-${section.title}`">
      <div class="papyrus-data-section-header-container">
        <h2 class="papyrus-data-section-header">
          {{ section.title }}
        </h2>
        <a
          :title="section.isExpanded.value ? 'Show less' : 'Show more'"
          :class="[
            'papyrus-data-show-more-link',
            section.isExpanded.value
              ? `papyrus-data-show-more-link--expanded`
              : ``,
          ]"
          @click="onClickShowMore(section)">
        </a>
      </div>
      <p v-if="!section.isExpanded.value" class="papyrus-data-section-text">
        {{ stripHtmlTags(section.content) }}
      </p>
      <transition name="slide-up-data-section">
        <span
          v-if="section.isExpanded.value"
          class="papyrus-data-section-text papyrus-data-section-text--expanded">
          <span
            v-html="section.content"
            class="papyrus-data-section-text-content"></span>
          <component
            v-if="section.additionalContent"
            :is="section.additionalContent" />
        </span>
      </transition>
    </div>
  </div>
</template>

<style lang="scss">
@import "@/assets/variables.scss";

.papyrus-data-sections {
  padding-top: 12px;
}
.papyrus-data-section {
  padding-bottom: 8px;
}
.papyrus-data-section-header {
  @include medium-bold;
}
.papyrus-data-section-header-container {
  display: flex;
}
.papyrus-data-section-text {
  @include medium-normal;
  margin-top: 0px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 24px;
  max-width: 70ch;
}
.papyrus-data-section-text--expanded {
  max-width: 100%;
  white-space: normal;
  height: auto;
  column-count: 2;
  column-gap: 54px;
}
@media (max-width: $tablet-breakpoint) {
  .papyrus-data-section-text--expanded {
    column-count: 1;
  }
}

.papyrus-data-section-text-content > p:first-of-type {
  margin-top: 0;
}
.papyrus-data-show-more-link {
  @include icon-link;
  background-image: url("@/assets/images/icon_chevron_left.svg");
  transform: rotate(270deg);
  float: none;
  user-select: none;
  background-size: 16px;
  background-repeat: no-repeat;
  background-position: center;
  width: 32px;
  background-size: 24px;
  margin-left: 6px;
  transition: transform 0.3s ease;
  &:hover {
    filter: brightness(0.8);
  }
}
.papyrus-data-show-more-link--expanded {
  transform: rotate(90deg);
  &:hover {
    filter: brightness(0.8);
  }
}
</style>
