import type { PropType } from "vue";
<script setup lang="ts">
import { type PropType } from "vue";
import InputText from "primevue/inputtext";
import IconField from "primevue/iconfield";
import InputIcon from "primevue/inputicon";
defineProps({
  name: String,
  onKeyDown: Function as PropType<(event: KeyboardEvent) => void>,
  inputValue: String,
  placeholder: String,
});
const emit = defineEmits(["updateValue"]);
function handleInput(event: Event) {
  emit("updateValue", (event.target as HTMLInputElement).value);
}
</script>

<template>
  <div>
    <IconField iconPosition="left">
      <InputIcon class="pi pi-search"> </InputIcon>
      <InputText
        :pt="{ root: 'archeion-search-field' }"
        :name="name"
        :placeholder="placeholder"
        type="search"
        :value="inputValue"
        @keydown="onKeyDown"
        @input="handleInput" />
    </IconField>
  </div>
</template>

<style lang="scss">
@import "@/assets/variables.scss";
.archeion-search-field {
  @include small-normal;
  width: 250px;
  height: 48px;
  // border-radius: 100vw !important;
}
</style>
