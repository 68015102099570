<script setup lang="ts">
import { StrapiBlocks } from "vue-strapi-blocks-renderer";
import { useApiStore } from "@/stores/useApiStore";
import PageLayout from "@/components/PageLayout.vue";

const apiStore = useApiStore();
</script>

<template>
  <PageLayout :isContentPage="true">
    <template #default>
      <div class="terms-header">Terms and conditions</div>
      <div class="terms-content">
        <StrapiBlocks :content="apiStore.data.editorialContent.terms" />
      </div>
    </template>
  </PageLayout>
</template>

<style lang="scss">
@import "@/assets/variables.scss";
.terms-header {
  @include large-bold;
  margin-top: 12px;
  padding-bottom: 12px;
}
.terms-content {
  max-width: $text-max-chars;
  img {
    width: 75vw;
    max-width: 1000px;
  }
}
</style>
