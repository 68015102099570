import { Mark, mergeAttributes } from "@tiptap/core";

import {
  HANDSHIFT_CLASS_NAME,
  HANDSHIFT_MARK_NAME,
} from "@/models/papyrusText";

export const TipTapHandshiftMark = Mark.create({
  name: HANDSHIFT_MARK_NAME,

  addOptions: {
    // @ts-expect-error vendor-specific
    HTMLAttributes: {},
  },

  parseHTML() {
    return [
      {
        tag: `span.${HANDSHIFT_CLASS_NAME}`,
        getAttrs: (node) => (node instanceof HTMLElement ? {} : false),
      },
    ];
  },

  // This function controls how the mark gets rendered to HTML
  renderHTML({ HTMLAttributes }) {
    return [
      "span",
      mergeAttributes({ class: HANDSHIFT_CLASS_NAME }, HTMLAttributes),
      0,
    ];
  },
});
