// Constants for routes
export const ROUTES = {
  HOME: {
    name: "Home",
    path: "/",
  },
  SEARCH: {
    name: "Search",
    path: "/search",
  },
  PAPYRUS: {
    name: "View",
    path: "/papyrus",
  },
  TEAM: {
    name: "Team",
    path: "/team",
  },
  LITERATURE: {
    name: "Bibliography",
    path: "/bibliography",
  },
  TERMS: {
    name: "Terms",
    path: "/terms",
  },
  ABOUT: {
    name: "About",
    path: "/about",
  },
  TIMELINE: {
    name: "Timeline",
    path: "/timeline",
  },
  ADMIN: {
    name: "Admin",
    path: "/admin",
  },
  ANNOTATION_EDITOR_LOGIN: {
    name: "Admin Login",
    path: "/admin/login",
  },
  ADMIN_LOGOUT: {
    name: "Logout",
    path: "/admin/logout",
  },
  ANNOTATION_EDITOR: {
    name: "Text Editor",
    path: "/admin/annotation-editor",
  },
  ANNOTATION_MANAGER: {
    name: "Annotation Manager",
    path: "/admin/annotation-manager",
  },
  ANNOTATION_HELP: {
    name: "Help",
    path: "/admin/annotation-help",
  },
};
