<script setup lang="ts">
import { ref, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import PageLayout from "@/components/PageLayout.vue";
import { AdminApi } from "@/services/AdminApi";
import { ROUTES } from "@/models/routes";
import {
  LOCAL_STORAGE_ADMIN_LOGIN,
  LOCAL_STORAGE_ADMIN_PASSWORD,
} from "@/services/Helpers";

const router = useRouter();
const route = useRoute();

onMounted(async () => {
  if (route.query.logout) {
    AdminApi.logout();
    credentials.value.username = "";
    credentials.value.password = "";
  } else {
    credentials.value.username =
      localStorage.getItem(LOCAL_STORAGE_ADMIN_LOGIN) || "";
    credentials.value.password =
      localStorage.getItem(LOCAL_STORAGE_ADMIN_PASSWORD) || "";
  }
});

const credentials = ref({
  username: "",
  password: "",
});
const hasError = ref(false);

const login = async () => {
  localStorage.setItem(LOCAL_STORAGE_ADMIN_LOGIN, credentials.value.username);
  localStorage.setItem(
    LOCAL_STORAGE_ADMIN_PASSWORD,
    credentials.value.password,
  );
  let user = null;
  user = await AdminApi.login(
    credentials.value.username,
    credentials.value.password,
  );

  if (user && user.jwt) {
    hasError.value = false;
    router.push(`${ROUTES.ANNOTATION_EDITOR.path}`);
  } else {
    hasError.value = true;
  }
};
</script>

<template>
  <PageLayout :has-content-container="false" :show-loading-shimmer="false">
    <template #static-content>
      <div class="admin-login-container">
        <h2>Archeion Papyrus Text Editor</h2>

        <div v-if="route.query.logout" class="admin-logout-message">
          You have been logged out.
        </div>

        <form class="admin-login-form-group" @submit.prevent="login">
          <label for="archeion-username">Username</label>
          <InputText
            id="archeion-username"
            v-model="credentials.username"
            style="width: 300px"
            required />

          <label for="archeion-password">Password</label>
          <InputText
            id="archeion-password"
            v-model="credentials.password"
            style="width: 300px"
            type="password"
            required />
          <Button class="admin-login-submit" type="submit" label="Login" />
        </form>
        <div v-if="hasError" class="admin-login-error-message">
          Invalid username or password
        </div>
      </div>
    </template>
  </PageLayout>
</template>

<style lang="scss">
@import "@/assets/variables.scss";

.admin-login-container {
  max-width: 600px;
  margin: auto;
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, $overlay-opacity);
  border-width: 0 1px 1px 1px;
  border-radius: 16px;
  border: 1px solid $container-divider-color;
}
.admin-login-form-group {
  display: grid;
  margin-bottom: 24px;
}
.admin-login-form-group label {
  margin-top: 16px;
}
.admin-login-error-message {
  color: $archeion-red;
}
.admin-login-submit {
  margin-top: 32px;
}
</style>
