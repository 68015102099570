<script setup lang="ts">
import Dialog from "primevue/dialog";
import { useAdminStore } from "@/stores/useAdminStore";

const adminStore = useAdminStore();
const closeDialog = () => {
  adminStore.closeDialog();
};
</script>
<template>
  <Dialog
    :visible="true"
    :maximizable="false"
    modal
    :draggable="false"
    :header="adminStore.data.dialog.title"
    @update:visible="closeDialog">
    <div class="modal-window-container">
      <div class="modal-window-input-fields">
        <slot />
      </div>
    </div>
    <template #footer>
      <slot name="footer" />
    </template>
  </Dialog>
</template>
<style lang="scss">
@import "@/assets/variables.scss";
.modal-window-container {
  min-width: 300px;
}
.modal-window-input-field {
  margin: 8px 0px 8px 0px;
}
.modal-window-input-field-label {
  @include medium-normal;
  margin-bottom: 4px;
}
.modal-window-input-field-content {
  width: $admin-modal-input-field-width;
}
</style>
