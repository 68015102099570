<script setup lang="ts">
import { ref, onMounted, watch, provide } from "vue";
import { useEditor, EditorContent, type Editor } from "@tiptap/vue-3";
import StarterKit from "@tiptap/starter-kit";
import SelectButton from "primevue/selectbutton";
import PapyrusEditorHeader from "./PapyrusEditorHeader.vue";
//import PapyrusEditorDeveloperOutput from "./PapyrusEditorDeveloperOutput.vue";
import PapyrusEditorToolbarUpper from "./PapyrusEditorToolbarUpper.vue";
import PapyrusEditorToolbarLower from "./PapyrusEditorToolbarLower.vue";

import { TiptapAnnotation } from "./TipTap/Extensions/TiptapAnnotation";
import { TipTapHandshift } from "./TipTap/Marks/TipTapHandshift";
import { TipTapHandshiftMark } from "./TipTap/Marks/TipTapHandshiftMark";
import { TipTapAnnotationMark } from "./TipTap/Marks/TipTapAnnotationMark";
import { TipTapLineBreak } from "./TipTap/Marks/TipTapLineBreak";
import { TipTapLineBreakMark } from "./TipTap/Marks/TipTapLineBreakMark";
import { TiptapPastePlainText } from "./TipTap/Extensions/TiptapPastePlainText";
import {
  highlightGroupedAnnotations,
  updateAnnotationsInEditor,
} from "./PapyrusEditorHelper";
import {
  distributeAnnotationsTags,
  checkCursorInsideMark,
} from "./TipTap/Extensions/TipTapAnnotationHelper";
import { AdminApi } from "@/services/AdminApi";
import { eventBus } from "@/services/EventBus";
import { useAdminStore } from "@/stores/useAdminStore";

import {
  ANNOTATION_CONTENT_IDENTIFIER,
  ANNOTATION_CLASS_NAME,
  ANNOTATION_INTERNAL_IDENTIFIER,
  LINEBREAK_MARK_NAME,
  HANDSHIFT_MARK_NAME,
} from "@/models/papyrusText";

const adminStore = useAdminStore();
const originalText = ref("");
const translationText = ref("");
const papyrusTitle = ref("");
const papyrusEdition = ref("");
const lastEditedBy = ref("");
const hasNotFoundError = ref(false);
const isPapyrusLoading = ref(true);
const isTextSelected = ref(false);
const isCursorInsideMark = ref(false);
const MIN_WAIT_TIME = 600; // in ms

eventBus.on("insertLineBreak", (data) => {
  const editorInstance = editor.value;
  if (!editorInstance) {
    return false;
  }
  editorInstance
    ?.chain()
    .focus()
    .setMark(LINEBREAK_MARK_NAME)
    .insertContent(data.lineBreakContent)
    .unsetMark(LINEBREAK_MARK_NAME)
    .insertContent(" ")
    .run();
});

eventBus.on("insertHandshift", (data) => {
  const editorInstance = editor.value;
  if (!editorInstance) {
    return false;
  }
  editorInstance
    ?.chain()
    .focus()
    .setMark(HANDSHIFT_MARK_NAME)
    .insertContent(data.handshiftContent)
    .unsetMark(HANDSHIFT_MARK_NAME)
    .insertContent(" ")
    .run();
});

eventBus.on("createAnnotation", (data) => {
  isTextSelected.value = false;
  const editorInstance = editor.value;
  if (!editorInstance) {
    return false;
  }
  const selection = editorInstance.state.selection;
  if (!selection) {
    return false;
  }
  distributeAnnotationsTags(editorInstance, data.annotationId);
  applyHighlightGroupedAnnotations();
});

eventBus.on("reassignAnnotation", (data) => {
  const editorInstance = editor.value;
  if (!editorInstance) {
    return false;
  }
  const selection = editorInstance.state.selection;
  if (!selection) {
    return false;
  }
  // newly annotated text, user selected text
  if (isTextSelected.value) {
    distributeAnnotationsTags(editorInstance, data.newAnnotationId);
  }
  // user clicked on existing annotation, and we need to reassign
  else {
    const parsed = updateAnnotationsInEditor(
      editorInstance,
      data.oldAnnotationId,
      data.newAnnotationId,
    );
    setContent(editorInstance, parsed);
    adminStore.data.isTextEditorDirty = true;
  }
  applyHighlightGroupedAnnotations();
  isTextSelected.value = false;
});

eventBus.on("deleteAnnotation", (data) => {
  isTextSelected.value = false;
  if (editor) {
    editor.value
      ?.chain()
      .focus()
      .deleteAnnotation(data.annotationMarkupInternalId)
      .run();
  }
});

const editor = useEditor({
  extensions: [
    StarterKit,
    TiptapAnnotation,
    TipTapAnnotationMark,
    TipTapHandshift,
    TipTapHandshiftMark,
    TipTapLineBreak,
    TipTapLineBreakMark,
    TiptapPastePlainText,
  ],
  content: "",
  onUpdate() {
    if (editor.value) {
      const textToCheck =
        adminStore.data.selectedTextType === "Translation"
          ? translationText.value
          : originalText.value;
      if (textToCheck === editor.value.getHTML()) {
        adminStore.data.isTextEditorDirty = false;
      } else {
        adminStore.data.isTextEditorDirty = true;
      }
    }
  },
  onSelectionUpdate({ editor }) {
    const selection = editor.state.selection;
    // Check if the selection size is greater than 0 (text is selected)
    isTextSelected.value = !selection.empty;
    // check if the cursor is inside an annotation
    if (!isTextSelected.value) {
      isCursorInsideMark.value = checkCursorInsideMark(editor.state);
    }
  },
});
provide("editor", editor);

onMounted(async () => {
  onLoad();
});

watch(
  () => adminStore.data.selectedTextType,
  async () => {
    await onLoad();
  },
);

const onLoad = async () => {
  await selectPapyrus();
  if (editor.value) {
    if (adminStore.data.selectedTextType === "Translation") {
      setContent(editor.value, translationText.value);
    } else {
      setContent(editor.value, originalText.value);
    }
    adminStore.data.isTextEditorDirty = false;
  }
};

const selectPapyrus = async () => {
  isPapyrusLoading.value = true;
  await new Promise((resolve) => setTimeout(resolve, MIN_WAIT_TIME));
  try {
    const response = await AdminApi.readPapyrus(
      adminStore.data.selectedPapyrusId as number,
    );
    if (response) {
      hasNotFoundError.value = false;
      papyrusTitle.value = response.data.attributes.title;
      papyrusEdition.value = response.data.attributes.name;
      translationText.value = response.data.attributes.englishText;
      originalText.value = response.data.attributes.greekText;
      lastEditedBy.value = response.data.attributes.papyrusLastEditedBy;
    } else {
      hasNotFoundError.value = true;
    }
  } catch (error) {
    console.error(error);
  } finally {
    isPapyrusLoading.value = false;
  }
};

const setContent = (editor: Editor, text: string) => {
  editor.commands.setContent(text);
  applyHighlightGroupedAnnotations();
};

const applyHighlightGroupedAnnotations = () => {
  setTimeout(() => {
    highlightGroupedAnnotations(
      document.getElementsByClassName(
        "editor-content-editable",
      )[0] as HTMLElement,
    );
  }, 50);
};
const handleAnnotationClick = (event: MouseEvent) => {
  const target = event.target as HTMLElement;
  if (target && target.classList.contains(ANNOTATION_CLASS_NAME)) {
    const annotationId =
      target.getAttribute(ANNOTATION_CONTENT_IDENTIFIER) || "";
    const internalId =
      target.getAttribute(ANNOTATION_INTERNAL_IDENTIFIER) || "";

    adminStore.data.selectedAnnotation = {
      annotationId: Number(annotationId),
      internalId: internalId,
    };
    if (adminStore.data.selectedTextType === "Translation") {
      adminStore.data.dialog = {
        type: "update-glossary",
        title: "Update Glossary Annotation",
      };
    } else {
      adminStore.data.dialog = {
        type: "update-apparatus",
        title: "Update Apparatus Annotation",
      };
    }
  }
};
const onChangeTextType = (selected: string) => {
  const newTextType = selected === "Translation" ? "Translation" : "Original";
  if (adminStore.data.isTextEditorDirty) {
    const isConfirmed = window.confirm(
      "You have unsaved changes. Discard changes?",
    );
    if (isConfirmed) {
      adminStore.data.selectedTextType = newTextType;
    }
  } else {
    adminStore.data.selectedTextType = newTextType;
  }
};
</script>

<template>
  <div class="admin-papyrus-editor">
    <div v-if="hasNotFoundError" class="editor-error">
      <br /><br />
      Error. Papyrus ID {{ adminStore.data.selectedPapyrusId }} was not found.
    </div>
    <div class="editor-content">
      <PapyrusEditorHeader :title="papyrusTitle" :edition="papyrusEdition" />
      <SelectButton
        size="large"
        :disabled="isPapyrusLoading"
        class="editor-toolbar-text-select"
        :options="['Original', 'Translation']"
        :modelValue="adminStore.data.selectedTextType"
        @update:modelValue="onChangeTextType"
        aria-labelledby="basic" />
      <PapyrusEditorToolbarUpper
        :isPapyrusLoading="isPapyrusLoading"
        :isTextSelected="isTextSelected"
        :isCursorInsideMark="isCursorInsideMark" />
      <editor-content
        v-if="!isPapyrusLoading"
        :editor="editor"
        class="editor-content-editable"
        @click="handleAnnotationClick" />

      <PapyrusEditorToolbarLower
        :isPapyrusLoading="isPapyrusLoading"
        :onSetContent="setContent"
        :onReload="onLoad" />
      <div class="editor-papyrus-last-edited">
        Last edited by: {{ lastEditedBy }}
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import "@/assets/variables.scss";
.admin-papyrus-editor {
  .editor-content {
    line-height: 28px;
    .ProseMirror {
      padding: 8px;
    }
    // line breaks are not allowed in the editor
    p {
      display: inline;
    }
  }
  .editor-error {
    color: $archeion-red;
  }
  .archeion-annotation {
    border: 2px solid $archeion-purple;
    cursor: pointer;
  }
  .archeion-annotation-hover {
    background-color: $archeion-purple;
    color: white;
    cursor: pointer;
  }
  .archeion-documentdivision {
    border: 2px solid #fbc02d;
    color: #fbc02d;
    border-radius: 100vw;
    padding: 1px 4px 1px 4px;
    margin: 0px 2px 0px 2px;
  }
  .editor-content-editable {
    border-right: 1px solid $container-divider-color;
    border-left: 1px solid $container-divider-color;
    padding: 8px;
    min-height: 200px;
  }
  .editor-toolbar-text-select {
    padding: 4px 0px 16px 0px;
  }
  .editor-papyrus-last-edited {
    @include tiny-normal;
    margin-top: 64px;
    text-align: right;
  }
}
</style>
