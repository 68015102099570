<script setup lang="ts">
// todo: refactor, too long
import { computed, onMounted, ref, watch, type ComputedRef } from "vue";
import DataTable from "primevue/datatable";
import type { DataTableExpandedRows } from "primevue/datatable";
import Column from "primevue/column";
import Button from "primevue/button";
import Dropdown from "primevue/dropdown";
import SelectButton from "primevue/selectbutton";
import { FilterMatchMode } from "primevue/api";

import SearchField from "@/components/SearchField.vue";
import ProgressSpinner from "@/components/LoadingState/ProgressSpinner.vue";
import { AdminApi } from "@/services/AdminApi";
import { useAdminStore } from "@/stores/useAdminStore";
import type { AnnotationContent } from "@/models/strapi";
import { type TextType } from "@/models/papyrusText";
import type {
  AnnotationInPapyrus,
  AnnotationsPerPapyrus,
} from "@/models/admin";
import { extractAnnotationsFromHtml } from "@/views/Admin/AnnotationEditor/PapyrusEditorHelper";
import { getPapyrusOptionLabel } from "@/services/Helpers";
import { ROUTES } from "@/models/routes";

type DataTableData = ({
  id: string;
} & AnnotationContent)[];
type AnnotationsInPapyri = Record<
  number, // annotation ID; as Record key: for easier access in datatable
  AnnotationInPapyrus[]
>;

const adminStore = useAdminStore();
const dt = ref();
const isLoading = ref(true);
const textSelect = ref<TextType>("Translation");
const filteredData = ref<ComputedRef<DataTableData> | []>([]);
const expandedRows = ref<DataTableExpandedRows[]>([]);
const searchQuery = ref("");
const filters = ref({
  global: { value: "", matchMode: FilterMatchMode.CONTAINS },
});
const filterByUsage = ref({ name: "Show all", value: "all" });

watch(searchQuery, (newValue) => {
  filters.value.global.value = newValue;
});

watch(
  () => adminStore.data.annotations,
  () => {
    applyFilters();
  },
  { deep: true },
);

onMounted(async () => {
  await onRefresh();
});

const exportCSV = () => {
  if (dt.value) {
    // Process the data to strip HTML tags
    const cleanedRows = dt.value.value.map((row: any) => {
      const cleanedRow = { ...row };
      Object.keys(cleanedRow).forEach((key) => {
        if (typeof cleanedRow[key] === "string") {
          cleanedRow[key] = cleanedRow[key].replace(/<\/?[^>]+(>|$)/g, ""); // Strip HTML tags
        }
      });
      return cleanedRow;
    });
    dt.value.exportCSV({}, cleanedRows);
  }
};
const onRefresh = async () => {
  isLoading.value = true;
  adminStore.data.annotations = {};
  await adminStore.fetchAnnotations();
  await adminStore.fetchPapyri();
  await new Promise((resolve) => setTimeout(resolve, 500));
  applyFilters();
  isLoading.value = false;
};

const annotations = computed(() => {
  const mapped = Object.entries(adminStore.data.annotations).map(
    ([id, annotation]): { id: string } & AnnotationContent => ({
      id,
      ...annotation,
      type: annotation.type ?? "",
      title: annotation.title ?? "",
      description: annotation.description ?? "",
    }),
  );

  return mapped;
});
const annotationsInPapyri = computed(() => {
  const result: AnnotationsInPapyri = {};

  adminStore.data.papyri.forEach((papyrus) => {
    const htmlContent =
      textSelect.value === "Original" ? papyrus.greekText : papyrus.englishText;
    const extractedAnnotations = extractAnnotationsFromHtml(htmlContent);

    extractedAnnotations.forEach((extractedAnnotation) => {
      // init array if it is the first entry
      if (!result[extractedAnnotation.annotationId]) {
        result[extractedAnnotation.annotationId] = [];
      }
      result[extractedAnnotation.annotationId].push({
        papyrusId: papyrus.id,
        papyrusTitle: papyrus.title,
        papyrusLabel: getPapyrusOptionLabel(papyrus),
        annotatedText: extractedAnnotation.annotatedText,
      });
    });
  });

  return result;
});
const papyriNamesPerAnnotation = computed(() => {
  return annotations.value.map((annotation) => {
    const papyri = annotationsInPapyri.value[Number(annotation.id)];
    if (papyri) {
      // Use Set to avoid duplicates and then sort the titles
      const papyriNames = Array.from(
        new Set(papyri.map((papyrus) => `'${papyrus.papyrusLabel}'`)),
      ).sort();
      return {
        ...annotation,
        papyriNames: papyriNames.join(", "),
      };
    }
    return {
      ...annotation,
      papyriNames: "", // Default value if not used
    };
  });
});

type AnnotationsPerPapyrusMap =
  | Record<number, Record<number, { annotatedTexts: string[] }>>
  | undefined;

const annotationsPerPapyrus: ComputedRef<AnnotationsPerPapyrusMap> = computed(
  () => {
    const result: AnnotationsPerPapyrus[] = [];

    Object.entries(annotationsInPapyri.value).forEach(
      ([annotationId, papyri]) => {
        // eslint-disable-next-line
        Object.entries(papyri).forEach(([_, papyri]) => {
          result.push({
            annotationId: Number(annotationId),
            papyrusId: Number(papyri.papyrusId),
            papyrusTitle: papyri.papyrusTitle,
            papyrusLabel: papyri.papyrusLabel,
            annotatedText: papyri.annotatedText || "",
          });
        });
      },
    );
    return transformData(result);
  },
);
function transformData(data: AnnotationsPerPapyrus[]) {
  if (!data) return;
  return data.reduce(
    (
      acc: Record<number, Record<number, { annotatedTexts: string[] }>>,
      { annotationId, papyrusId, annotatedText }: AnnotationsPerPapyrus,
    ) => {
      acc[annotationId] = acc[annotationId] || {};
      acc[annotationId][papyrusId] = acc[annotationId][papyrusId] || {
        annotatedTexts: [],
      };
      acc[annotationId][papyrusId].annotatedTexts.push(annotatedText);
      return acc;
    },
    {},
  );
}
const applyFilters = () => {
  filteredData.value = papyriNamesPerAnnotation.value.filter(
    ({ type }) => type === textSelect.value,
  );
  filteredData.value = applyFilterByUsage(filteredData.value);
  expandedRows.value = [];
};

const onDeleteAnnotation = async (id: number) => {
  if (annotationsInPapyri.value[id]) {
    adminStore.data.message = {
      type: "error",
      content: "Can not delete used annotation. Remove from papyri first.",
    };
  } else {
    try {
      await AdminApi.deleteAnnotation(id);
      adminStore.removeAnnotation(id);
      adminStore.data.message = {
        type: "success",
        content: "Deleted annotation",
      };
      applyFilters();
    } catch (error) {
      adminStore.data.message = {
        type: "error",
        content: "Could not delete annotation",
      };
    }
  }
};
const onEditAnnotation = async (id: number) => {
  adminStore.data.listManager.selectedAnnotationId = id;
  adminStore.data.dialog = {
    type: "list-edit-annotation",
    title: `Edit ${textSelect.value === "Original" ? "Appararatus" : "Glossary"} annotation`,
  };
};

const applyFilterByUsage = (currentAnnotations: DataTableData) => {
  if (filterByUsage.value.value === "unused") {
    return currentAnnotations.filter((row) => {
      return !annotationsInPapyri.value[Number(row.id)];
    });
  } else if (filterByUsage.value.value === "used") {
    return currentAnnotations.filter((row) => {
      return annotationsInPapyri.value[Number(row.id)];
    });
  }
  return currentAnnotations;
};

const isExpandedInDataTable = (id: number): boolean => {
  return expandedRows.value[id] ? true : false;
};
</script>

<template>
  <div class="editor-annotation-list-toolbar-top">
    <SelectButton
      :disabled="isLoading"
      class="editor-toolbar-text-select"
      v-model="textSelect"
      :options="['Original', 'Translation']"
      @change="applyFilters" />
  </div>
  <div class="editor-content-loading" v-if="isLoading">
    <ProgressSpinner />
  </div>

  <div v-if="!isLoading" class="editor-annotation-list-table">
    <DataTable
      ref="dt"
      paginator
      :rows="50"
      v-model:expandedRows="expandedRows"
      showGridlines
      :value="filteredData"
      v-model:filters="filters"
      :globalFilterFields="['title', 'content', 'description']"
      dataKey="id"
      :sortOrder="1"
      :class="`p-datatable-sm p-datatable-striped`">
      <template #header>
        <div class="editor-annotation-list-panel">
          <!-- Left side -->
          <div class="editor-annotation-list-panel-left">
            <!-- Filter by content -->
            <div class="editor-annotation-list-filter">
              <label
                class="editor-annotation-list-label"
                for="editor-annotation-list-search"
                >Filter by content, title, description</label
              >

              <SearchField
                name="editor-annotation-list-search"
                placeholder="Start typing to filter"
                :inputValue="searchQuery"
                @updateValue="searchQuery = $event" />
            </div>

            <!-- Filter by usage -->
            <div class="editor-annotation-list-filter">
              <label class="editor-annotation-list-label" for="filterByUsage"
                >Filter by usage in papyri</label
              >
              <Dropdown
                v-model="filterByUsage"
                :options="[
                  { name: 'Show all', value: 'all' },
                  { name: 'Show unused only', value: 'unused' },
                  { name: 'Show used only', value: 'used' },
                ]"
                optionLabel="name"
                :selectedOption="'all'"
                @change="applyFilters"
                placeholder="Filter by usage" />
            </div>
          </div>
        </div>
      </template>

      <template #empty> No annotations found. </template>
      <Column field="id" class="editor-annotation-list-column" header="ID">
        <template #body="slotProps">
          <div v-html="slotProps.data.id"></div>
        </template>
      </Column>
      <Column
        field="content"
        class="editor-annotation-list-column"
        header="Content">
        <template #body="slotProps">
          <div v-html="slotProps.data.content"></div>
        </template>
      </Column>
      <Column
        class="editor-annotation-list-column"
        field="title"
        header="Title"></Column>
      <Column
        class="editor-annotation-list-column"
        field="description"
        header="Description"></Column>
      <!--   <Column field="id" header="ID"></Column>-->
      <Column field="edit" header="Actions" :exportable="false">
        <template #body="slotProps">
          <div class="editor-annotation-list-actions">
            <Button
              title="Edit annotation"
              icon="pi pi-file-edit"
              severity="success"
              @click="onEditAnnotation(slotProps.data.id)"></Button>
            <Button
              title="Delete annotation"
              icon="pi pi-trash"
              severity="primary"
              @click="onDeleteAnnotation(slotProps.data.id)"></Button>
          </div>
        </template>
      </Column>
      <Column
        field="papyriNames"
        class="editor-annotation-list-column"
        header="Used in Papyri">
        <template #body="slotProps">
          <div>{{ slotProps.data.papyriNames }}</div>
        </template>
      </Column>
      <Column expander header="Show usage" style="width: 3em">
        <template #body="slotProps">
          <button
            v-if="annotationsInPapyri[slotProps.data.id]?.length > 0"
            class="p-row-toggler p-link"
            @click="slotProps.rowTogglerCallback">
            <i
              :class="{
                'pi pi-chevron-down': isExpandedInDataTable(
                  Number(slotProps.data.id),
                ),
                'pi pi-chevron-right':
                  annotationsInPapyri[slotProps.data.id]?.length > 0,
              }"></i>
          </button>
        </template>
        ></Column
      >

      <template #expansion="{ data }">
        <ul class="annotation-list-row-expansion">
          <li
            class="annotation-list-row-expansion-item"
            v-for="(texts, papyrusId) in annotationsPerPapyrus?.[
              Number(data.id)
            ]"
            :key="papyrusId">
            <a
              v-bind:href="`${ROUTES.ANNOTATION_EDITOR.path}/${papyrusId}`"
              title="Open in Text Editor"
              >{{
                adminStore.data.papyri.find(
                  (item) => Number(item.id) === Number(papyrusId),
                )?.title
              }}</a
            >
            <ul>
              <li
                class="annotation-list-row-expansion-item-2"
                v-for="(text, index) in texts.annotatedTexts"
                :key="index">
                {{ text }}
              </li>
            </ul>
          </li>
        </ul>
      </template>
    </DataTable>
  </div>

  <!-- Right side -->
  <div class="editor-annotation-list-panel-right">
    <Button
      class="editor-annotation-list-button"
      severity="info"
      label="Reload"
      icon="pi pi-refresh"
      :disabled="isLoading"
      @click="onRefresh"
      style="width: 120px" />

    <Button
      title="Export current view to Excel (CSV format)"
      icon="pi pi-file-excel"
      label="Export"
      @click="exportCSV()"
      style="width: 120px" />
  </div>
</template>

<style lang="scss">
@import "@/assets/variables.scss";

.editor-annotation-list-toolbar-top {
  display: flex;
  justify-content: space-between;
}
.editor-annotation-list-actions {
  display: flex;
  column-gap: 8px;
}
.editor-annotation-list-panel {
  overflow-x: auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 16px;
  background-color: #f9f9f9;
  border-bottom: 1px solid #ddd;
  width: 100%;
}

.editor-annotation-list-table {
  .editor-annotation-list-button {
    margin-left: 4px;
    margin-top: 4px;
  }
  .editor-annotation-list-column,
  .editor-annotation-list-column,
  .editor-annotation-list-column {
    @include small-normal;
    p {
      display: inline;
    }
  }
  .p-column-title {
    @include small-bold;
  }
  .annotation-list-row-expansion {
    @include small-normal;
  }
  .p-paginator-page.p-highlight {
    background-color: $archeion-red;
    color: $archeion-white;
  }
}
.editor-annotation-list-filter {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.editor-annotation-list-panel-left {
  display: flex;
  flex-direction: row;
  gap: 32px;
}
.editor-annotation-list-panel-right {
  display: flex;
  gap: 8px;
}
.editor-annotation-list-label {
  white-space: nowrap;
}
.annotation-list-row-expansion-item {
  margin: 0px 0 8px 0;
}
.annotation-list-row-expansion-item-2 {
  margin: 4px 0 4px 0;
}
</style>
