<script setup lang="ts">
// same but with internal data
import { onMounted } from "vue";

import { useApiStore } from "@/stores/useApiStore";
import PageLayout from "@/components/PageLayout.vue";

const apiStore = useApiStore();

interface TLWindow extends Window {
  TL: any;
}

declare const window: TLWindow;

onMounted(() => {
  const cssLink = document.createElement("link");
  cssLink.rel = "stylesheet";
  cssLink.href =
    "https://cdn.knightlab.com/libs/timeline3/latest/css/timeline.css";
  document.head.appendChild(cssLink);

  const script = document.createElement("script");
  script.src = "https://cdn.knightlab.com/libs/timeline3/latest/js/timeline.js";
  script.async = false;
  script.onload = async () => {
    await new Promise((resolve) => setTimeout(resolve, 1000));
    const data = await parseData();
    new window.TL.Timeline("timeline-embed", data, {
      // https://timeline.knightlab.com/docs/options.html
      default_bg_color: { r: 255, g: 255, b: 255 },
      // timenav_height: 420,
      // marker_height_min: 32,
      // marker_padding: 8,
      timenav_position: "top",
    });
  };
  document.head.appendChild(script);
});

const parseData = async () => {
  return transformToTimelineJson();
};

const transformToTimelineJson = (): any => {
  const timelineJson: any = {
    title: {
      media: {
        url: "",
        caption: "",
        credit: "",
      },
      text: {
        headline: "Archeion",
        text: "Timeline",
      },
    },
    events: [],
  };
  apiStore.data.papyri.forEach((papyrus) => {
    const event: any = {
      media: {
        url: "",
        caption: "",
        credit: "",
      },
      start_date: {
        year: papyrus.dateFrom.year,
        month: papyrus.dateFrom.month,
        day: papyrus.dateFrom.day,
      },
      end_date: {
        year: papyrus.dateTo.year,
        month: papyrus.dateTo.month,
        day: papyrus.dateTo.day,
      },
      text: {
        headline: papyrus.name,
        text:
          papyrus.title +
          ` <a class="timeline-papyrus-link" target="_self" href="/papyrus/${papyrus.id}">Show papyrus</a>`,
      },
    };
    timelineJson.events.push(event);
  });
  return timelineJson;
};
</script>

<template>
  <PageLayout :isContentPage="true">
    <template #default>
      <div class="about-archeion-header"></div>
      <div id="timeline-embed" style="width: 100%; height: 600px"></div>
    </template>
  </PageLayout>
</template>

<style lang="scss">
@import "@/assets/variables.scss";
.about-archeion-header {
  padding-bottom: 36px;
}
.timeline-papyrus-link {
  display: block;
  margin-top: 24px;
  color: $archeion-red !important;
}
.tl-timeline,
.tl-headline,
.tl-text-content p,
.tl-headline-date,
.tl-slidenav-title,
.tl-headline-date {
  font-family: Inter !important;
}
.tl-attribution {
  display: none;
}
.tl-slidenav-icon {
  color: $archeion-red !important;
}
.tl-text-content-container {
  .tl-headline {
    font-size: 24px !important;
    text-transform: none !important;
  }
}
</style>
