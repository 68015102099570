<script setup lang="ts">
import { computed } from "vue";
import type { Papyrus } from "@/models/strapi";
import { getDateStr } from "@/services/Helpers";

const props = defineProps<{
  papyrus: Papyrus | undefined;
}>();

const papyrusMetadata = computed(() => {
  if (!props.papyrus) return [];

  const {
    dateFrom,
    dateTo,
    provenance,
    collection,
    language,
    material,
    form,
    content,
    keywords_flattened,
  } = props.papyrus;

  const dateStr = getDateStr(dateFrom, dateTo);

  return [
    {
      label: "Date",
      value: dateStr ?? "",
    },
    { label: "Language/Script", value: language || "-" },
    { label: "Provenance", value: provenance || "-" },
    { label: "Material", value: material || "-" },
    { label: "Collection", value: collection || "-" },
    { label: "Form", value: form || "-" },
    { label: "Content", value: content || "-" },
    { label: "Keywords", value: keywords_flattened || "-" },
  ];
});
const toCssClassName = (str: string) => {
  return str.replace(/[^a-z0-9]/gi, "_").toLowerCase();
};
</script>

<template>
  <div class="papyrus-metadata">
    <div
      v-for="entry in papyrusMetadata"
      :class="[
        'papyrus-metadata-entry',
        `papyrus-metadata-entry-${toCssClassName(entry.label)}`,
      ]"
      :key="entry.label">
      <span class="papyrus-metadata-entry-label">{{ entry.label }}: </span>
      <span class="papyrus-metadata-entry-value" v-html="entry.value"></span>
    </div>
  </div>
</template>

<style lang="scss">
@import "@/assets/variables.scss";

.papyrus-metadata {
  display: grid;
  margin-top: 12px;
  gap: 0px;
  column-gap: 8px;
  row-gap: 8px;
  grid-template-columns: 1fr;
}

@media (min-width: $tablet-breakpoint) {
  .papyrus-metadata {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    row-gap: 0px;
  }
  .papyrus-metadata-entry:nth-child(2n) {
    margin-left: 24px;
  }
}

.papyrus-metadata-entry {
  grid-column: span 1;
}
.papyrus-metadata-entry {
  @include small-bold;
}
.papyrus-metadata-entry-label {
  @include small-normal;
}
.papyrus-metadata-entry-value {
  p {
    display: inline;
  }
}
</style>
