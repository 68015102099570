// manages global app data

import { defineStore } from "pinia";
import { ref } from "vue";
import type { TextType } from "@/models/papyrusText";

type AppData = {
  selectedPapyrusId: number;
  papyrusText: {
    showModal: boolean;
    modalContent: string;
    selectedAnnotationId: number;
    annotationType: TextType;
  };
};

export const useAppStore = defineStore("app", () => {
  const data = ref<AppData>({
    selectedPapyrusId: 0,
    papyrusText: {
      showModal: false,
      modalContent: "",
      selectedAnnotationId: 0,
      annotationType: "Original",
    },
  });
  const openPapyrusModal = (
    content: string,
    annotationId: number,
    annotationType: TextType,
  ) => {
    data.value.papyrusText.showModal = true;
    data.value.papyrusText.modalContent = content;
    data.value.papyrusText.selectedAnnotationId = annotationId;
    data.value.papyrusText.annotationType = annotationType;
  };
  const closePapyrusModal = () => {
    data.value.papyrusText.showModal = false;
    data.value.papyrusText.modalContent = "";
    data.value.papyrusText.selectedAnnotationId = 0;
  };

  return {
    data,
    openPapyrusModal,
    closePapyrusModal,
  };
});
