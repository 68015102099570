<script setup lang="ts">
import { ref, inject, type ShallowRef } from "vue";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import type { Editor } from "@tiptap/vue-3";

import Dialog from "primevue/dialog";
const props = defineProps({
  onClose: Function,
});

const isValid = ref(false);
const editor = inject("glossary-editor") as ShallowRef<Editor | undefined>;
let prevUrl = editor.value?.getAttributes("link").href || "";
const url = ref<string>(prevUrl);

const onChange = () => {
  if (url.value.startsWith("http")) {
    isValid.value = true;
  } else {
    isValid.value = false;
  }
};
const onRemove = () => {
  if (editor.value) {
    editor.value
      .chain()
      .focus()
      .extendMarkRange("link")
      // @ts-ignore
      .unsetLink()
      .run();
    if (props.onClose) {
      props.onClose();
    }
  }
  return false;
};

const onSave = () => {
  if (editor.value) {
    editor.value
      .chain()
      .focus()
      .extendMarkRange("link")
      // @ts-ignore
      .setLink({
        href: url.value,
        target: url.value.includes("archeion") ? "_self" : "_blank",
      })
      .run();
    if (props.onClose) {
      props.onClose();
    }
  }
  return false;
};
</script>
<template>
  <Dialog
    :visible="true"
    :maximizable="false"
    modal
    :draggable="false"
    header="Edit link"
    @update:visible="
      () => {
        if (props.onClose) {
          props.onClose();
        }
      }
    ">
    <div class="modal-window-container">
      <div class="modal-window-input-fields">
        <InputText
          placeholder="Enter an URL starting with http:// or https://"
          v-model="url"
          @input="onChange"
          style="width: 450px"
          required />
      </div>
    </div>
    <template #footer>
      <Button
        label="Cancel"
        icon="pi pi-times"
        severity="info"
        @click="
          () => {
            if (props.onClose) {
              props.onClose();
            }
          }
        " />
      <Button
        :disabled="!prevUrl"
        label="Remove"
        icon="pi pi-times"
        severity="error"
        @click="onRemove" />
      <Button
        :disabled="isValid === false"
        label="OK"
        severity="success"
        icon="pi pi-check"
        @click="onSave" />
    </template>
  </Dialog>
</template>
<style lang="scss">
@import "@/assets/variables.scss";
</style>
