<script setup lang="ts">
import { ref, onMounted, provide } from "vue";
import { useEditor, EditorContent } from "@tiptap/vue-3";
import StarterKit from "@tiptap/starter-kit";
// @ts-ignore
import Link from "@tiptap/extension-link";
import Button from "primevue/button";
import Textarea from "primevue/textarea";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";

import { useAdminStore } from "@/stores/useAdminStore";
import { eventBus } from "@/services/EventBus";
import DialogWindowLayout from "./DialogWindowLayout.vue";
import { createAnnotation, updateAnnotation } from "./DialogWindowHelper";
import EditGlossaryAssignExisting from "./EditGlossaryAssignExisting.vue";
import EditGlossaryAddLinkModal from "./EditGlossaryAddLinkModal.vue";

const props = defineProps({
  isUpdate: Boolean, // if false: it's a new annotation
});
const showLinkModal = ref(false);
const adminStore = useAdminStore();
const glossaryDescription = ref("");
const glossaryTitle = ref("");
const activeTabIndex = ref(0);
const isTextSelected = ref(false);

onMounted(async () => {
  if (props.isUpdate) {
    const annotation = adminStore.getAnnotation(
      Number(adminStore.data.selectedAnnotation.annotationId),
    );

    if (annotation) {
      await new Promise((resolve) => setTimeout(resolve, 50));
      setContent(annotation.content);
      glossaryDescription.value = annotation.description;
      glossaryTitle.value = annotation.title;
    } else {
      adminStore.data.message = {
        type: "error",
        content:
          "Annotation was not found. Remove mark manually and reenter text.",
      };

      adminStore.closeDialog();
    }
  }
});
const editor = useEditor({
  extensions: [
    StarterKit,
    Link.configure({
      openOnClick: true,
    }),
  ],

  content: "",
  onSelectionUpdate({ editor }) {
    const selection = editor.state.selection;
    isTextSelected.value = !selection.empty;
  },
});
provide("glossary-editor", editor);

const setContent = (text: string) => {
  if (editor.value) {
    editor.value.commands.setContent(text);
  }
};

const onCreateNew = async () => {
  glossaryTitle.value = glossaryTitle.value ? glossaryTitle.value.trim() : "";
  glossaryDescription.value = glossaryDescription.value
    ? glossaryDescription.value.trim()
    : "";
  const glossaryContent = editor.value?.getHTML().trim();
  const cleanedContent = glossaryContent?.replace("<p></p>", "");

  if (!cleanedContent) {
    adminStore.data.message = {
      type: "error",
      content: "Content field is required",
    };
    return false;
  }

  if (props.isUpdate) {
    try {
      updateAnnotation(adminStore.data.selectedAnnotation.annotationId, {
        content: glossaryContent || "",
        title: glossaryTitle.value,
        description: glossaryDescription.value,
        type: adminStore.data.selectedTextType,
      });
    } catch (e) {
      adminStore.data.message = {
        type: "error",
        content: "Could not update annotation",
      };
      console.error(e);
    }
  } else {
    try {
      createAnnotation({
        content: glossaryContent || "",
        title: glossaryTitle.value,
        description: glossaryDescription.value,
        type: adminStore.data.selectedTextType,
      });
    } catch (e) {
      adminStore.data.message = {
        type: "error",
        content: "Could not create annotation",
      };
      console.error(e);
    }
  }
  adminStore.closeDialog();
};

const onAssign = async () => {
  if (!adminStore.data.reassignedAnnotationId) {
    adminStore.data.message = {
      type: "error",
      content: "Select an annotation to assign.",
    };
    return false;
  } else {
    eventBus.emit("reassignAnnotation", {
      newAnnotationId: Number(adminStore.data.reassignedAnnotationId),
      oldAnnotationId: Number(adminStore.data.selectedAnnotation.annotationId),
    });
  }
  adminStore.closeDialog();
};

const onUnassign = async () => {
  // note: glossary annotations can only be deleted in Manager
  eventBus.emit("deleteAnnotation", {
    annotationMarkupInternalId: adminStore.data.selectedAnnotation.internalId,
  });
  onCancel();
};

const onCancel = () => {
  adminStore.closeDialog();
};
const setBold = () => {
  if (editor.value) {
    editor.value.chain().focus().toggleBold().run();
  }
};
const setItalic = () => {
  if (editor.value) {
    editor.value.chain().focus().toggleItalic().run();
  }
};
</script>
<template>
  <DialogWindowLayout>
    <div class="modal-window-container-glossary">
      <TabView v-model:activeIndex="activeTabIndex">
        <TabPanel :header="isUpdate ? 'Edit' : 'Create new'">
          <div class="modal-window-input-field glossary-content-editor-field">
            <div class="modal-window-input-field-label">Content *</div>

            <div class="glossary-content-editor-toolbar">
              <Button
                :disabled="!isTextSelected"
                label="Bold"
                title="Toggle Bold"
                icon="pi pi-code"
                severity="info"
                class="editor-toolbar-button"
                @click="setBold"></Button>

              <Button
                :disabled="!isTextSelected"
                label="Italic"
                title="Toggle Italic"
                icon="pi pi-code"
                severity="info"
                class="editor-toolbar-button"
                @click="setItalic"></Button>

              <Button
                :disabled="!isTextSelected"
                label="Add Link"
                title="Select text and enter a URL in the dialog to add link. To remove a link, select the text that contains the link and choose 'Remove' in the dialog."
                icon="pi pi-link"
                severity="help"
                class="editor-toolbar-button"
                @click="showLinkModal = true"></Button>
            </div>

            <editor-content :editor="editor" class="editor-content-editable" />
          </div>

          <div class="modal-window-input-field">
            <div class="modal-window-input-field-label">Title (internal)</div>
            <Textarea
              autoResize
              v-model="glossaryTitle"
              class="modal-window-input-field-content"
              id="glossary-title" />
          </div>
          <div class="modal-window-input-field">
            <div class="modal-window-input-field-label">
              Description (internal)
            </div>
            <Textarea
              autoResize
              v-model="glossaryDescription"
              class="modal-window-input-field-content"
              id="glossary-description" />
          </div>
          <EditGlossaryAddLinkModal
            :onClose="() => (showLinkModal = false)"
            v-if="showLinkModal"></EditGlossaryAddLinkModal>
        </TabPanel>
        <TabPanel header="Assign existing">
          <EditGlossaryAssignExisting
        /></TabPanel>
      </TabView>
    </div>
    <template #footer>
      <Button
        label="Cancel"
        icon="pi pi-times"
        severity="info"
        @click="onCancel"></Button>
      <Button
        v-if="isUpdate"
        label="Unassign"
        severity="primary"
        icon="pi pi-check"
        @click="onUnassign"></Button>
      <Button
        :label="isUpdate ? 'Update' : 'Assign'"
        severity="success"
        icon="pi pi-check"
        @click="activeTabIndex === 0 ? onCreateNew() : onAssign()"></Button>
    </template>
  </DialogWindowLayout>
</template>
<style lang="scss">
@import "@/assets/variables.scss";

.modal-window-container-glossary {
  height: 60vh;
  width: 80vw;

  .editor-content-editable {
    border: 1px solid $container-divider-color;
    padding: 8px;
    min-height: 120px;
    width: $admin-modal-input-field-width;
  }
  .editor-content {
    line-height: 28px;
  }
  .ProseMirror {
    padding: 8px;
    min-height: 120px;
  }
  // line breaks are not allowed in the editor
  p {
    display: inline;
  }
}
.glossary-content-editor-toolbar {
  display: flex;
  justify-content: flex-start;
  width: $admin-modal-input-field-width;
  gap: 8px;
}
.glossary-content-editor-field {
  width: $admin-modal-input-field-width;
  padding-bottom: 24px;
}
</style>
