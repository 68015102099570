<script setup lang="ts">
import { useApiStore } from "@/stores/useApiStore";
import PageLayout from "@/components/PageLayout.vue";

const apiStore = useApiStore();
</script>

<template>
  <PageLayout :isContentPage="true">
    <template #default>
      <div class="literature-list-header">Bibliography</div>
      <div
        class="literature-list-content"
        v-html="apiStore.data.literatureList"></div>
    </template>
  </PageLayout>
</template>

<style lang="scss">
@import "@/assets/variables.scss";
.literature-list-header {
  @include large-bold;
  margin-top: 12px;
  padding-bottom: 12px;
}
.literature-list-content {
  max-width: $text-max-chars;
  img {
    width: 75vw;
    max-width: 1000px;
  }
  p {
    // allow empty paragraphs
    display: flex;
  }
}
</style>
