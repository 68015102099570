// @ts-expect-error wait for update
import { definePreset } from "primevue/themes";
// @ts-expect-error wait for update
import Aura from "primevue/themes/primeone/aura";
import variables from "@/assets/variables.module.scss";

export const ArcheionPreset = definePreset(Aura, {
  primitive: {
    red: {
      // button background
      500: variables["archeion-red"],
      // button hover
      600: "rgba(190,73,57,.76)",
    },
  },
  semantic: {
    primary: {
      50: "{red.50}",
      100: "{red.100}",
      200: "{red.200}",
      300: "{red.300}",
      400: "{red.400}",
      500: "{red.500}",
      600: "{red.600}",
      700: "{red.700}",
      800: "{red.800}",
      900: "{red.900}",
      950: "{red.950}",
    },
  },
});
