<script setup lang="ts">
import { StrapiBlocks } from "vue-strapi-blocks-renderer";
import { useApiStore } from "@/stores/useApiStore";
import PageLayout from "@/components/PageLayout.vue";

const apiStore = useApiStore();
</script>

<template>
  <PageLayout :isContentPage="true">
    <template #default>
      <div class="about-archeion-header">About Archeion</div>
      <div class="about-archeion-content">
        <StrapiBlocks
          :content="apiStore.data.editorialContent.about_archeion" />
      </div>
    </template>
  </PageLayout>
</template>

<style lang="scss">
@import "@/assets/variables.scss";
.about-archeion-header {
  @include large-bold;
  margin-top: 12px;
  padding-bottom: 12px;
}
.about-archeion-content {
  max-width: $text-max-chars;
  img {
    width: 75vw;
    max-width: 1000px;
  }
}
</style>
