<script setup lang="ts">
import { onMounted } from "vue";

import { useApiStore } from "@/stores/useApiStore";
import PageLayout from "@/components/PageLayout.vue";
import PapyrusList from "@/views/Search/PapyrusList.vue";

const apiStore = useApiStore();

onMounted(async () => {
  await apiStore.initialLoad();
});
</script>

<template>
  <PageLayout :isContentPage="true">
    <template #default>
      <div class="papyrus-search-heading">Search papyri</div>
      <PapyrusList />
    </template>
  </PageLayout>
</template>

<style lang="scss">
@import "@/assets/variables.scss";

.papyrus-search-heading {
  @include large-bold;
  margin-top: 12px;
  padding-bottom: 12px;
}
</style>
