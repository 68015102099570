// manages all data fetching and app state for the backend
import axios from "axios";
import { defineStore } from "pinia";
import { ref } from "vue";
import type { AdminData } from "@/models/admin";
import type { AnnotationContent } from "@/models/strapi";

import type {
  Annotations,
  StrapiPapyrus,
  StrapiResult,
  StrapiAnnotations,
} from "@/models/strapi";
import { ENDPOINTS } from "@/services/ApiEndpoints";
import { getBaseStrapiUrl } from "@/services/Helpers";

const BASE_URL = getBaseStrapiUrl();

export const useAdminStore = defineStore("admin", () => {
  const data = ref<AdminData>({
    token: "",
    message: { type: "info", content: "" },
    dialog: { type: "closed", title: "" },
    selectedPapyrusId: 0,
    selectedTextType: "Original",
    selectedAnnotation: {
      annotationId: 0,
      internalId: "",
    },
    annotations: {},
    papyri: [],
    reassignedAnnotationId: 0,
    isTextEditorDirty: false,
    listManager: { selectedAnnotationId: 0 },
  });

  const closeDialog = () => {
    data.value.dialog = { type: "closed", title: "" };
  };

  const fetchAnnotations = async () => {
    try {
      const response = await axios.get<StrapiResult<StrapiAnnotations>>(
        `${BASE_URL}${ENDPOINTS.annotation}`,
      );
      data.value.annotations = response.data.data.reduce((acc, item) => {
        acc[item.id] = {
          content: item.attributes.Content,
          type: item.attributes.Type,
          title: item.attributes.Title,
          description: item.attributes.Description,
        };
        return acc;
      }, {} as Annotations);
    } catch (error) {
      console.error("Failed to fetch papyrus-annotations:", error);
      throw Error;
    }
  };
  const addAnnotation = (identifier: number, annotation: AnnotationContent) => {
    data.value.annotations[identifier] = annotation;
  };

  const updateAnnotation = (id: number, annotation: AnnotationContent) => {
    if (data.value.annotations[id]) {
      data.value.annotations[id] = annotation;
    } else {
      console.error("updateAnnotation in store failed", id, annotation);
    }
  };

  const getAnnotation = (identifier: number) => {
    if (data.value.annotations[identifier]) {
      return data.value.annotations[identifier];
    } else {
      console.error(
        "Not found: annotation with ID ",
        identifier,
        data.value.annotations,
      );
      return null;
    }
  };
  const removeAnnotation = (identifier: number) => {
    delete data.value.annotations[identifier];
  };

  const fetchPapyri = async () => {
    try {
      const response = await axios.get<StrapiResult<StrapiPapyrus>>(
        // populate: fetch all related data too
        `${BASE_URL}${ENDPOINTS.papyrus}?populate=*`,
      );

      // parse API data for frontend
      data.value.papyri = response.data.data.map((item) => {
        const parsedItem = {
          id: item.id,
          ...item.attributes,
        };

        return parsedItem;
      });
    } catch (error) {
      console.error("Failed to fetch papyri:", error);
      throw Error;
    }
  };

  return {
    data,
    closeDialog,
    fetchAnnotations,
    fetchPapyri,
    addAnnotation,
    updateAnnotation,
    getAnnotation,
    removeAnnotation,
  };
});
