<script setup lang="ts">
import { ref, onMounted } from "vue";
import { useEditor, EditorContent } from "@tiptap/vue-3";
import StarterKit from "@tiptap/starter-kit";
import Button from "primevue/button";
import Textarea from "primevue/textarea";

import { useAdminStore } from "@/stores/useAdminStore";

import DialogWindowLayout from "./DialogWindowLayout.vue";

import { updateAnnotation } from "./DialogWindowHelper";
import type { AnnotationContent } from "@/models/strapi";
const adminStore = useAdminStore();

const glossaryDescription = ref("");
const glossaryTitle = ref("");
const isTextSelected = ref(false);
const annotation = ref<AnnotationContent | null>(null);

onMounted(async () => {
  annotation.value = adminStore.getAnnotation(
    Number(adminStore.data.listManager.selectedAnnotationId),
  );

  if (annotation.value) {
    await new Promise((resolve) => setTimeout(resolve, 50));
    setContent(annotation.value.content);
    glossaryDescription.value = annotation.value.description;
    glossaryTitle.value = annotation.value.title;
  } else {
    adminStore.data.message = {
      type: "error",
      content: "Annotation was not found. Contact admin.",
    };
    adminStore.closeDialog();
  }
});
const editor = useEditor({
  extensions: [StarterKit],

  content: "",
  onSelectionUpdate({ editor }) {
    const selection = editor.state.selection;
    isTextSelected.value = !selection.empty;
  },
});

const setContent = (text: string) => {
  if (editor.value) {
    editor.value.commands.setContent(text);
  }
};

const onSave = async () => {
  glossaryTitle.value = glossaryTitle.value ? glossaryTitle.value.trim() : "";
  glossaryDescription.value = glossaryDescription.value
    ? glossaryDescription.value.trim()
    : "";
  const glossaryContent = editor.value?.getHTML().trim();
  const cleanedContent = glossaryContent?.replace("<p></p>", "");
  if (!cleanedContent) {
    adminStore.data.message = {
      type: "error",
      content: "Content field is required",
    };
    return false;
  }
  if (!annotation.value?.type) {
    adminStore.data.message = {
      type: "error",
      content: "Error in annotation type. Contact admin.",
    };
    return false;
  }
  try {
    updateAnnotation(adminStore.data.listManager.selectedAnnotationId, {
      content: glossaryContent || "",
      title: glossaryTitle.value,
      description: glossaryDescription.value,
      type: annotation.value?.type,
    });
  } catch (e) {
    adminStore.data.message = {
      type: "error",
      content: "Could not update annotation",
    };
    console.error(e);
  }

  adminStore.closeDialog();
};

const onCancel = () => {
  adminStore.closeDialog();
};
const setBold = () => {
  if (editor.value) {
    editor.value.chain().focus().toggleBold().run();
  }
};
const setItalic = () => {
  if (editor.value) {
    editor.value.chain().focus().toggleMark("italic").run();
  }
};
</script>
<template>
  <DialogWindowLayout>
    <div class="modal-window-container-glossary">
      <div class="modal-window-input-field glossary-content-editor-field">
        <div class="modal-window-input-field-label">Content *</div>

        <div class="glossary-content-editor-toolbar">
          <Button
            :disabled="!isTextSelected"
            label="Bold"
            title="Toggle Bold"
            icon="pi pi-code"
            severity="info"
            class="editor-toolbar-button"
            @click="setBold"></Button>

          <Button
            :disabled="!isTextSelected"
            label="Italic"
            title="Toggle Italic"
            icon="pi pi-code"
            severity="info"
            class="editor-toolbar-button"
            @click="setItalic"></Button>
        </div>

        <editor-content :editor="editor" class="editor-content-editable" />
      </div>

      <div class="modal-window-input-field">
        <div class="modal-window-input-field-label">Title (internal)</div>
        <Textarea
          autoResize
          v-model="glossaryTitle"
          class="modal-window-input-field-content"
          id="glossary-title" />
      </div>
      <div class="modal-window-input-field">
        <div class="modal-window-input-field-label">Description (internal)</div>
        <Textarea
          autoResize
          v-model="glossaryDescription"
          class="modal-window-input-field-content"
          id="glossary-description" />
      </div>
    </div>
    <template #footer>
      <Button
        label="Cancel"
        icon="pi pi-times"
        severity="info"
        @click="onCancel"></Button>

      <Button
        label="Save"
        severity="success"
        icon="pi pi-check"
        @click="onSave()"></Button>
    </template>
  </DialogWindowLayout>
</template>
<style lang="scss">
@import "@/assets/variables.scss";

.modal-window-container-glossary {
  height: 60vh;
  width: 80vw;
  .editor-content-editable {
    border: 1px solid $container-divider-color;
    padding: 8px;
    min-height: 120px;
    width: $admin-modal-input-field-width;
  }
  .editor-content {
    line-height: 28px;
  }
  .ProseMirror {
    padding: 8px;
    min-height: 120px;
  }
  // line breaks are not allowed in the editor
  p {
    display: inline;
  }
}
.glossary-content-editor-toolbar {
  display: flex;
  justify-content: flex-start;
  width: $admin-modal-input-field-width;
  padding: 0 0 8px 0;
}
.glossary-content-editor-field {
  width: $admin-modal-input-field-width;
  padding-bottom: 24px;
}
</style>
