<script setup lang="ts">
import type { Papyrus } from "@/models/strapi";
import { computed } from "vue";

const props = defineProps<{
  papyrus: Papyrus | undefined;
}>();

const papyrusLinks = computed(() => {
  if (!props.papyrus) return [];

  return [
    {
      label: props.papyrus?.name,
      key: "papyri-info",
      value: props.papyrus?.papyriLink ?? "",
    },
    {
      label: `TM ${props.papyrus?.trismegistos}`,
      key: "trismegistos",
      value: props.papyrus?.trismegistos
        ? `https://www.trismegistos.org/text/${props.papyrus?.trismegistos}`
        : "",
    },
    {
      label: "View Images",
      key: "image-link",
      value: props.papyrus?.collectionLink ?? "",
    },
  ].filter((entry) => entry.value);
});
</script>

<template>
  <div class="papyrus-toolbar">
    <a
      v-bind:key="entry.key"
      v-for="entry in papyrusLinks"
      :href="entry.value"
      rel="external nofollow"
      target="_blank"
      :class="['papyrus-toolbar-link', `papyrus-toolbar-link-${entry.key}`]">
      <span class="papyrus-toolbar-link-text"> {{ entry.label }}</span>
    </a>
  </div>
</template>

<style lang="scss">
@import "@/assets/variables.scss";

.papyrus-toolbar {
  display: flex;
  flex-wrap: wrap;
  column-gap: 12px;
}
.papyrus-toolbar-link {
  @include icon-link;
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 8px;
  padding-right: 8px;
}

.papyrus-toolbar-link-papyri-info {
  background-image: url("@/assets/images/icon_book.svg");
}
.papyrus-toolbar-link-trismegistos {
  background-image: url("@/assets/images/icon_tm.svg");
}
.papyrus-toolbar-link-image-link {
  background-image: url("@/assets/images/icon_photo.svg");
}
.papyrus-toolbar-link-text {
  margin-left: 14px;
}
</style>
