<script setup lang="ts">
import { onMounted, onUnmounted, ref } from "vue";
import Header from "@/components/PageHeader.vue";
import Footer from "@/components/PageFooter.vue";
import PapyrusTextModal from "@/components/PapyrusTextModal.vue";
import TextShimmer from "@/components/LoadingState/TextShimmer.vue";
import { useApiStore } from "@/stores/useApiStore";

const apiStore = useApiStore();
const parallaxOffset = ref(0);
const BACKGROUND_ANIMATION_SPEED = 0.25; // in percent

defineProps({
  isContentPage: {
    type: Boolean,
    default: false,
  },
  showLoadingShimmer: {
    type: Boolean,
    default: true,
  },
  isCenteredContent: {
    type: Boolean,
    default: false,
  },
  hasContentContainer: {
    type: Boolean,
    default: true,
  },
  isAdminLayout: {
    type: Boolean,
    default: false,
  },
});

onMounted(async () => {
  try {
    await apiStore.initialLoad();
  } catch (error) {
    console.error(error);
  }
  window.addEventListener("scroll", handleScroll);
});
onUnmounted(async () => {
  window.removeEventListener("scroll", handleScroll);
});

const handleScroll = () => {
  parallaxOffset.value = window.scrollY * BACKGROUND_ANIMATION_SPEED;
};
</script>

<template>
  <div
    class="home-parallax-background"
    :style="{ 'background-position-y': `${parallaxOffset}px` }" />

  <Header />

  <div class="content" :class="isAdminLayout ? 'content-admin' : ''">
    <div
      :class="
        isContentPage
          ? 'content-container-more-visible content-container'
          : 'content-container'
      ">
      <slot name="static-content" />
      <div v-if="apiStore.data.state === 'loading' && showLoadingShimmer">
        <TextShimmer />
      </div>
      <div
        v-else-if="apiStore.data.state === 'ready'"
        :class="{
          'centered-content-body': isCenteredContent,
        }">
        <slot />
      </div>
      <div v-else-if="apiStore.data.state === 'error'">
        <p class="text-error">
          An unknown error has occured. Please try again later.
        </p>
      </div>
    </div>
  </div>

  <Footer />
  <PapyrusTextModal />
</template>

<style lang="scss">
@import "@/assets/variables.scss";

.content {
  flex-grow: 1;
  max-width: $max-width;
  padding: 12px;
}
@media (min-width: $max-width) {
  .content {
    min-width: calc($max-width - 24px);
    margin: 0 auto;
  }
}
// in admin allow wider max-width
@media (min-width: 1400px) {
  .content-admin {
    min-width: 1400px;
    max-width: 1400px;
  }
}

.content-container {
  padding: 24px 64px 24px 64px;
  background-color: rgba(255, 255, 255, $overlay-opacity);
  border: 1px solid $container-divider-color;
  border-width: 0 1px 1px 1px;
  border-radius: 16px;
  box-shadow: $box-shadow;
  // prevent layout shifting
  min-height: calc(100vh - 144px);
}
@media (max-width: $phone-breakpoint) {
  .content-container {
    padding: 24px;
  }
}
.text-error {
  text-align: center;
  @include medium-normal;
  color: $text-color;
  color: $archeion-red;
}
.centered-content-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -24px;
}
</style>
