<script setup lang="ts">
import { ref, watch, onMounted } from "vue";
import { useRouter } from "vue-router";
import { useAppStore } from "@/stores/useAppStore";
import { useApiStore } from "@/stores/useApiStore";
import { extractAnnotationsFromHtml } from "@/views/Admin/AnnotationEditor/PapyrusEditorHelper";
import { ROUTES } from "@/models/routes";
import type { AnnotatedTextProps } from "@/models/papyrusText";

const router = useRouter();
const apiStore = useApiStore();
const appStore = useAppStore();
const annotations = ref<AnnotatedTextProps[]>([]);
const currentIndex = ref(0);

const onUpdate = () => {
  annotations.value = getAnnotations();
  currentIndex.value = getIndexByAnnotationId(
    appStore.data.papyrusText.selectedAnnotationId,
  );
};
onMounted(() => {
  onUpdate();
});

watch(
  () => [
    appStore.data.papyrusText.selectedAnnotationId,
    appStore.data.papyrusText.annotationType,
  ],
  () => {
    onUpdate();
  },
);
const getAnnotations = (): AnnotatedTextProps[] => {
  const papyrus = apiStore.data.papyri.find(
    (item: { id: number }) =>
      Number(item.id) === Number(appStore.data.selectedPapyrusId),
  );
  if (papyrus) {
    const text =
      appStore.data.papyrusText.annotationType === "Original"
        ? papyrus.greekText
        : papyrus.englishText;
    const extractedAnnotations = extractAnnotationsFromHtml(text);
    return filterUniqueAnnotationGroups(extractedAnnotations);
  }
  return [];
};
const filterUniqueAnnotationGroups = (
  data: AnnotatedTextProps[],
): AnnotatedTextProps[] => {
  const seenGroupIds = new Set<number>();
  return data.filter((item) => {
    if (seenGroupIds.has(item.annotationGroupId)) {
      return false;
    } else {
      seenGroupIds.add(item.annotationGroupId);
      return true;
    }
  });
};

const getIndexByAnnotationId = (annotationId: number): number => {
  return annotations.value.findIndex(
    (item) => item.annotationId === annotationId,
  );
};

const navigateToAnnotation = (index: number) => {
  if (annotations.value[index]) {
    currentIndex.value = index;
    const annotationId = annotations.value[index].annotationId;
    router.push({
      path: `${ROUTES.PAPYRUS.path}/${appStore.data.selectedPapyrusId}`,
      query: { annotationId, scrollToTop: "false" },
    });
  }
};

const onPrev = (event: Event) => {
  event.preventDefault();

  const prevIndex =
    currentIndex.value === 0
      ? annotations.value.length - 1
      : currentIndex.value - 1;

  navigateToAnnotation(prevIndex);
};

const onNext = (event: Event) => {
  event.preventDefault();
  const nextIndex =
    currentIndex.value === annotations.value.length - 1
      ? 0
      : currentIndex.value + 1;

  navigateToAnnotation(nextIndex);
};
</script>

<template>
  <div class="papyrus-modal-navigation-pager">
    <a
      @click="onPrev"
      class="papyrus-modal-navigation-link papyrus-modal-navigation-prev"></a>
    <div class="papyrus-modal-navigation-counter">
      {{ (currentIndex + 1).toString().padStart(2, "0") }}
      of {{ annotations.length.toString().padStart(2, "0") }}
    </div>
    <a
      @click="onNext"
      class="papyrus-modal-navigation-link papyrus-modal-navigation-next"></a>
  </div>
</template>

<style lang="scss">
@import "@/assets/variables.scss";
.papyrus-modal-navigation-pager {
  display: flex;
  align-items: center;
}
.papyrus-modal-navigation-counter {
  @include small-bold;
  margin-left: 8px;
  margin-right: 8px;
}
.papyrus-modal-navigation-link {
  cursor: pointer;
  background-image: url("@/assets/images/icon_chevron_left.svg");
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  user-select: none;
}
.papyrus-modal-navigation-link:hover {
  filter: brightness(0.8);
}
.papyrus-modal-navigation-next {
  transform: rotate(180deg);
}
</style>
