import { parse as nodeHtmlParse } from "node-html-parser";

import DefaultText from "@/components/PapyrusTextComponents/DefaultText.vue";
import AnnotatedText from "@/components/PapyrusTextComponents/AnnotatedText.vue";
import HandshiftSymbol from "@/components/PapyrusTextComponents/HandshiftSymbol.vue";
import {
  ANNOTATION_CLASS_NAME,
  ANNOTATION_CONTENT_IDENTIFIER,
  HANDSHIFT_CLASS_NAME,
  LINEBREAK_CLASS_NAME,
  type ParserResponse,
  type TextSegment,
  type DocumentDivision,
} from "@/models/papyrusText";
import { extractAnnotationsFromHtml } from "@/views/Admin/AnnotationEditor/PapyrusEditorHelper";

export const parse = (content: string): ParserResponse => {
  const annotationGroupMapping = getAnnotationGroupMapping(content);

  const normalized = removeSoftReturns(content);
  const rowLabels = parseRowLabels(normalized);
  const parsed = replaceLineBreakTags(normalized);
  const rows = parsed
    .split("<br>")
    .map((row) => splitIntoSegements(row, annotationGroupMapping));
  if (rows.length > 0 && Array.isArray(rows[0]) && rows[0].length === 0) {
    // skip first entry (it is empty)
    rows.shift();
  }
  return {
    rowLabels: rowLabels.lineNumbers,
    documentDivisions: rowLabels.documentDivisions,
    rows: rows,
  };
};

const getAnnotationGroupMapping = (content: string) => {
  const result: { [key: number]: number } = {};
  const annotations = extractAnnotationsFromHtml(content);
  annotations.forEach((annotation) => {
    result[annotation.annotationId] = annotation.annotationGroupId;
  });
  return result;
};

const parseRowLabels = (html: string) => {
  const root = nodeHtmlParse(html);
  const lineNumbers: number[] = [];
  const documentDivisions: DocumentDivision[] = [];
  const spans = root.querySelectorAll(`span.${LINEBREAK_CLASS_NAME}`);
  spans.forEach((span, index: number) => {
    const content = span.innerHTML;
    // example content: "1", "21", "Verso | 2", "Column 1 | 21", "Recto | 112"
    const parts = content.split("|");
    const lineNumber = Number(parts.pop());
    lineNumbers.push(lineNumber);

    // contains documentDivision
    if (parts[1]) {
      documentDivisions.push({
        startRow: index + 1,
        label: parts[0].trim(),
      });
    }
    if (parts.length >= 1) {
      const documentDivisionLabel = parts.join(" ") || "";
      documentDivisions.push({
        startRow: index + 1,
        label: documentDivisionLabel,
      });
    }
  });
  return {
    lineNumbers: lineNumbers,
    documentDivisions: documentDivisions,
  };
};

// in Admin editor soft returns are allowed for better usability
const removeSoftReturns = (html: string) => {
  return html.replace(/<br\s*\/?>/gi, "");
};
const replaceLineBreakTags = (html: string): string => {
  const parser: DOMParser = new DOMParser();
  const doc: Document = parser.parseFromString(
    `<body>${html}</body>`,
    "text/html",
  );
  const spans: NodeListOf<HTMLSpanElement> = doc.querySelectorAll(
    `span.${LINEBREAK_CLASS_NAME}`,
  );
  spans.forEach((span) => {
    const br: HTMLElement = document.createElement("br");
    span.parentNode?.replaceChild(br, span);
  });
  const innerHTML: string = doc.body.innerHTML;
  return innerHTML;
};

const isSpanNode = (node: Node, nodeElement: Element) => {
  return (
    node.nodeType === Node.ELEMENT_NODE &&
    nodeElement.tagName &&
    nodeElement.tagName === "SPAN" &&
    nodeElement.classList
  );
};

const isAnnotationNode = (node: Node, nodeElement: Element) => {
  return (
    isSpanNode(node, nodeElement) &&
    nodeElement.classList.contains(ANNOTATION_CLASS_NAME) &&
    nodeElement.getAttribute(ANNOTATION_CONTENT_IDENTIFIER)
  );
};

const isHandshiftNode = (node: Node, nodeElement: Element) => {
  return (
    isSpanNode(node, nodeElement) &&
    nodeElement.classList.contains(HANDSHIFT_CLASS_NAME)
  );
};

const splitIntoSegements = (
  row: string,
  annotationGroupMapping: { [key: number]: number },
): TextSegment[] => {
  const segments: TextSegment[] = [];
  const parser = new DOMParser();
  const doc = parser.parseFromString(row, "text/html");
  const children = doc.body.childNodes;

  Array.from(children).flatMap((node: Node) => {
    // normal text nodes
    const nodeElement = node as Element;

    if (node.nodeType === Node.TEXT_NODE) {
      segments.push({
        component: DefaultText,
        props: { content: node.textContent || "" },
      });
    }
    // annotations (apparatus, glossary)
    else if (isAnnotationNode(node, nodeElement)) {
      const annotationId =
        Number(nodeElement.getAttribute(ANNOTATION_CONTENT_IDENTIFIER)) || 0;
      const props = {
        annotatedText: nodeElement.innerHTML,
        annotationId: annotationId,
        annotationGroupId: annotationGroupMapping[annotationId],
      };
      segments.push({
        component: AnnotatedText,
        props: props,
      });
    }
    // handshift symbols
    else if (isHandshiftNode(node, nodeElement)) {
      const props = {
        content: nodeElement.innerHTML,
      };
      segments.push({
        component: HandshiftSymbol,
        props: props,
      });
    }
  });
  return segments;
};
