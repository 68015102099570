import { Mark, markInputRule } from "@tiptap/core";

import {
  HANDSHIFT_CLASS_NAME,
  HANDSHIFT_MARK_NAME,
  HANDSHIFT_EXTENSION_NAME,
} from "@/models/papyrusText";

export const TipTapHandshift = Mark.create({
  name: HANDSHIFT_EXTENSION_NAME,

  parseHTML() {
    return [
      {
        tag: `span.${HANDSHIFT_MARK_NAME}`,
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ["span", { ...HTMLAttributes, class: HANDSHIFT_CLASS_NAME }, 0];
  },

  addInputRules() {
    return [
      markInputRule({
        find: /\(hand (\d+)\)/,
        type: this.type,
      }),
    ];
  },
});
