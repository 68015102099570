<script setup lang="ts">
import { computed } from "vue";
import PapyrusAnnotatedTextToolbar from "@/views/Papyrus/PapyrusAnnotatedTextToolbar.vue";
import type { Papyrus } from "@/models/strapi";
import { parse } from "@/services/ArcheionParser";

const props = defineProps<{
  papyrus: Papyrus | undefined;
}>();

const parsedOriginal = computed(() => {
  const original = props.papyrus?.greekText ?? "";
  const parsedOriginal = parse(original);
  return parsedOriginal;
});
const parsedTranslation = computed(() => {
  const translation = props.papyrus?.englishText ?? "";
  const parsedTranslation = parse(translation);
  return parsedTranslation;
});

const documentDivisions = computed(() => {
  const parsedDivisions = {} as Record<number, string>;
  parsedOriginal.value.documentDivisions.forEach((division) => {
    const startRow = division.startRow - 1;
    parsedDivisions[startRow] = division.label;
  });
  return parsedDivisions;
});
</script>

<template>
  <div class="papyrus-text-container">
    <div
      class="papyrus-text"
      v-for="(_, index) in parsedOriginal.rows"
      :key="index">
      <div
        v-if="documentDivisions[index] !== undefined"
        :class="`papyrus-text-document-division ${
          index === 0 ? 'papyrus-text-document-division--first' : ''
        }`"
        title="Document division"
        class="papyrus-text-document-division">
        {{ documentDivisions[index] }}
      </div>
      <div class="papyrus-text-col-row-number">
        {{ parsedOriginal.rowLabels[index] }}
      </div>
      <div class="papyrus-text-col papyrus-text-col-original">
        <component
          v-for="(segment, i) in parsedOriginal.rows[index]"
          :is="segment.component"
          :key="i"
          v-bind="segment.props" />
      </div>

      <div class="papyrus-text-col-row-number">
        {{ parsedOriginal.rowLabels[index] }}
      </div>
      <div class="papyrus-text-col papyrus-text-col-translation">
        <component
          v-for="(segment, i) in parsedTranslation.rows[index]"
          :is="segment.component"
          :key="i"
          v-bind="segment.props" />
      </div>
    </div>
    <PapyrusAnnotatedTextToolbar />
  </div>
</template>

<style lang="scss">
@import "@/assets/variables.scss";

.papyrus-text-container {
  // allow to scroll below annotation view
  padding-bottom: 12vw;
}

.papyrus-text {
  display: grid;
  grid-template-columns: 8px minmax(0, 1fr) 8px minmax(0, 1fr);
  gap: 8px;
  margin-left: -24px;
  margin-right: -24px;
}
.papyrus-text-col {
  @include medium-normal;
  padding: 8px;
  line-height: $extra-line-height;
  overflow-wrap: anywhere;
  margin-top: -2px;
}
.papyrus-text-col-row-number {
  color: $archeion-yellow;
  @include tiny-normal;
  padding: 8px;
  margin-top: 6px;
  // allow 3-digit numbers
  margin-left: -2px;
}
.papyrus-text-document-division {
  @include tiny-bold;
  color: $archeion-yellow;
  grid-column: 1 / -1;
  margin-left: 24px;
  margin-top: 16px;
}
.papyrus-text-document-division--first {
  margin-top: 0px;
}
.archeion-handshift {
  @include tiny-normal;
  border: 1px solid $archeion-yellow;
  border-radius: 100vw;
  color: $archeion-yellow;
  padding: 2px;
}
</style>
