<script setup lang="ts">
import { ref } from "vue";
import Button from "primevue/button";
import Textarea from "primevue/textarea";

import { useAdminStore } from "@/stores/useAdminStore";
import { AdminApi } from "@/services/AdminApi";
import { eventBus } from "@/services/EventBus";
import { createAnnotation, updateAnnotation } from "./DialogWindowHelper";
import DialogWindowLayout from "./DialogWindowLayout.vue";
const props = defineProps({
  isUpdate: Boolean, // if false: it's a new annotation
});

const adminStore = useAdminStore();
const apparatusContent = ref("");
const apparatusDescription = ref("");
const apparatusTitle = ref("");

if (props.isUpdate) {
  const annotation = adminStore.getAnnotation(
    Number(adminStore.data.selectedAnnotation.annotationId),
  );

  if (annotation) {
    apparatusContent.value = annotation.content;
    apparatusDescription.value = annotation.description;
    apparatusTitle.value = annotation.title;
  } else {
    adminStore.data.message = {
      type: "error",
      content:
        "Annotation was not found. Remove the annotation with the Unmark button",
    };
    adminStore.closeDialog();
  }
}

const onSave = async () => {
  apparatusTitle.value = apparatusTitle.value.trim();
  apparatusContent.value = apparatusContent.value.trim();
  apparatusDescription.value = apparatusDescription.value.trim();
  if (!apparatusContent.value) {
    adminStore.data.message = {
      type: "error",
      content: "Content field is required",
    };
    return false;
  }

  if (props.isUpdate) {
    try {
      updateAnnotation(adminStore.data.selectedAnnotation.annotationId, {
        content: apparatusContent.value,
        title: apparatusTitle.value,
        description: apparatusDescription.value,
        type: adminStore.data.selectedTextType,
      });
    } catch (e) {
      adminStore.data.message = {
        type: "error",
        content: "Could not update annotation",
      };
      console.error(e);
    }
  } else {
    try {
      createAnnotation({
        content: apparatusContent.value,
        title: apparatusTitle.value,
        description: apparatusDescription.value,
        type: adminStore.data.selectedTextType,
      });
    } catch (e) {
      adminStore.data.message = {
        type: "error",
        content: "Could not create annotation",
      };
      console.error(e);
    }
  }
  adminStore.closeDialog();
};

const onRemove = async () => {
  await AdminApi.deleteAnnotation(
    adminStore.data.selectedAnnotation.annotationId,
  );
  adminStore.removeAnnotation(
    Number(adminStore.data.selectedAnnotation.annotationId),
  );
  eventBus.emit("deleteAnnotation", {
    annotationMarkupInternalId: adminStore.data.selectedAnnotation.internalId,
  });
  onCancel();
};

const onCancel = () => {
  adminStore.closeDialog();
};
</script>

<template>
  <DialogWindowLayout>
    <div class="modal-window-input-field">
      <div class="modal-window-input-field-label">Content*</div>
      <Textarea
        id="apparatus-content"
        v-model="apparatusContent"
        class="modal-window-input-field-content"
        auto-resize
        required="true" />
      <div class="modal-window-input-field">
        <div class="modal-window-input-field-label">Title (internal)</div>
        <Textarea
          id="apparatus-title"
          v-model="apparatusTitle"
          auto-resize
          class="modal-window-input-field-content" />
      </div>
      <div class="modal-window-input-field">
        <div class="modal-window-input-field-label">Description (internal)</div>
        <Textarea
          id="apparatus-description"
          v-model="apparatusDescription"
          auto-resize
          class="modal-window-input-field-content" />
      </div>
    </div>
    <template #footer>
      <Button
        label="Cancel"
        icon="pi pi-times"
        severity="info"
        @click="onCancel" />
      <Button
        v-if="isUpdate"
        label="Remove"
        severity="primary"
        icon="pi pi-check"
        @click="onRemove" />
      <Button
        label="Save"
        severity="success"
        icon="pi pi-check"
        @click="onSave" />
    </template>
  </DialogWindowLayout>
</template>
<style lang="scss">
@import "@/assets/variables.scss";
</style>
