<script setup lang="ts">
import { ref } from "vue";
import Button from "primevue/button";
import Textarea from "primevue/textarea";
import { useAdminStore } from "@/stores/useAdminStore";
import { AdminApi } from "@/services/AdminApi";
import DialogWindowLayout from "./DialogWindowLayout.vue";
import { onMounted } from "vue";

const adminStore = useAdminStore();
const content = ref("");

onMounted(async () => {
  try {
    const response = await AdminApi.readPapyrus(
      adminStore.data.selectedPapyrusId,
    );
    if (response) {
      content.value = response.data.attributes.papyrusNotes || "";
    }
  } catch (error) {
    console.error(error);
  }
});

const onSaveNotes = async () => {
  try {
    const response = await AdminApi.updatePapyrusNotes(
      adminStore.data.selectedPapyrusId,
      content.value,
    );
    if (response) {
      onCancel();
      adminStore.data.message = {
        type: "success",
        content: "Papyrus notes saved",
      };
    }
  } catch (error) {
    console.error(error);
  }
};

const onCancel = () => {
  adminStore.closeDialog();
};
</script>
<template>
  <DialogWindowLayout>
    <div class="editor-notes-container">
      <div class="modal-window-input-field">
        <div class="modal-window-input-field-label">Notes (internal)</div>
        <Textarea
          rows="4"
          autoResize
          v-model="content"
          class="modal-window-input-field-content"
          id="edit-notes-texarea" />
      </div>
    </div>

    <div class="modal-window-container-edit-notes"></div>
    <template #footer>
      <Button
        label="Cancel"
        icon="pi pi-times"
        severity="info"
        @click="onCancel"></Button>

      <Button
        label="Save"
        severity="success"
        icon="pi pi-check"
        @click="onSaveNotes"></Button>
    </template>
  </DialogWindowLayout>
</template>
<style lang="scss">
@import "@/assets/variables.scss";

.modal-window-container-glossary {
  height: 60vh;
  width: 80vw;

  .editor-content-editable {
    border: 1px solid $container-divider-color;
    padding: 8px;
    min-height: 120px;
    width: $admin-modal-input-field-width;
  }
  .editor-content {
    line-height: 28px;
  }
  .ProseMirror {
    padding: 8px;
    min-height: 120px;
  }
  // line breaks are not allowed in the editor
  p {
    display: inline;
  }
}
.glossary-content-editor-toolbar {
  display: flex;
  justify-content: flex-start;
  width: $admin-modal-input-field-width;
  gap: 8px;
}
.glossary-content-editor-field {
  width: $admin-modal-input-field-width;
  padding-bottom: 24px;
}
</style>
