<script setup lang="ts">
import { ref, computed, watch, onMounted } from "vue";
import AutoComplete from "primevue/autocomplete";
import type { AutoCompleteCompleteEvent } from "primevue/autocomplete";
import type { Papyrus } from "@/models/strapi";
import { getPapyrusOptionLabel } from "@/services/Helpers";

interface Props {
  onSelect: (papyrusId: number) => void;
  optionsData: Papyrus[];
  selectedPapyrusId: number;
  isWide: boolean;
}
const props = defineProps<Props>();
const selectedPapyrus = ref({ name: "", value: 0 });

const filteredOptions = ref();
const options = computed(() => {
  return props.optionsData
    .map((papyrus) => {
      return { name: getPapyrusOptionLabel(papyrus), value: papyrus.id };
    })
    .sort((a, b) => a.name.localeCompare(b.name));
});

watch(
  () => props.selectedPapyrusId,
  (newValue) => {
    selectedPapyrus.value = getOptionByValue(newValue);
    updateFilteredOptions();
  },
);
onMounted(() => {
  selectedPapyrus.value = getOptionByValue(props.selectedPapyrusId);
  updateFilteredOptions();
});

const getOptionByValue = (newValue: number) => {
  const selected = options.value.find((option) => option.value === newValue);
  return selected || { name: "", value: 0 };
};

const updateFilteredOptions = () => {
  filteredOptions.value = options.value.map((option) => ({
    ...option,
    selected: option.value === selectedPapyrus.value?.value,
  }));
};
const onSearch = (event: AutoCompleteCompleteEvent) => {
  if (!event.query.trim().length) {
    filteredOptions.value = [...options.value];
  } else {
    filteredOptions.value = options.value.filter(
      (option: { name: string; value: number }) => {
        return option.name.toLowerCase().includes(event.query.toLowerCase());
      },
    );
  }
};
const onItemSelect = () => {
  props.onSelect?.call(this, selectedPapyrus.value.value);
};
</script>
<template>
  <div class="papyrus-selector-container">
    <AutoComplete
      :pt="{
        root: `archeion-dropdown ${props.isWide ? 'archeion-dropdown-wide' : ''} `,
        item: 'archeion-dropdown-item',
        itemLabel: 'archeion-dropdown-label',
        panel: 'archeion-autocomplete-panel',
      }"
      placeholder="Type to search for papyri"
      class="papyrus-selector-inputfield"
      dropdown
      v-model="selectedPapyrus"
      optionLabel="name"
      :suggestions="filteredOptions"
      @complete="onSearch"
      @item-select="onItemSelect" />
  </div>
</template>
<style lang="scss">
@import "@/assets/variables.scss";
.papyrus-selector-container {
  display: flex;
  align-items: center;
}
</style>
