<script lang="ts" setup>
import { ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";

import { ROUTES } from "@/models/routes";
import { AdminApi } from "@/services/AdminApi";
import { useAppStore } from "@/stores/useAppStore";

const route = useRoute();
const router = useRouter();
const appStore = useAppStore();
const isMobileMenuOpen = ref(false);
const publicLinks = ref<
  Array<{ name: string; path: string; iconName: string }>
>([
  {
    path: ROUTES.HOME.path,
    name: ROUTES.HOME.name,
    iconName: "home",
  },
  {
    path: ROUTES.SEARCH.path,
    name: ROUTES.SEARCH.name,
    iconName: "search",
  },
  {
    path: ROUTES.PAPYRUS.path,
    name: ROUTES.PAPYRUS.name,
    iconName: "eye",
  },
  {
    path: ROUTES.TIMELINE.path,
    name: ROUTES.TIMELINE.name,
    iconName: "chart-line",
  },
  {
    path: ROUTES.LITERATURE.path,
    name: ROUTES.LITERATURE.name,
    iconName: "book",
  },
  {
    path: ROUTES.ABOUT.path,
    name: ROUTES.ABOUT.name,
    iconName: "info-circle",
  },

  {
    path: ROUTES.TEAM.path,
    name: ROUTES.TEAM.name,
    iconName: "users",
  },
]);

const adminLinks = ref<Array<{ name: string; path: string; iconName: string }>>(
  [
    {
      path: ROUTES.ADMIN.path,
      name: ROUTES.ADMIN.name,
      iconName: "user-edit",
    },
    {
      path: ROUTES.ADMIN_LOGOUT.path,
      name: ROUTES.ADMIN_LOGOUT.name,
      iconName: "sign-out",
    },
  ],
);

const navigateTo = (path: string) => {
  appStore.closePapyrusModal();
  router.push(path);
};

watch(
  () => route.path,
  () => {
    // prevent open dialog if user hits back button in browser
    appStore.closePapyrusModal();
  },
  { immediate: true },
);

const isPublicLinkActive = (linkPath: string): boolean => {
  const isPapyrusView =
    linkPath === ROUTES.PAPYRUS.path &&
    route.path.includes(ROUTES.PAPYRUS.path);
  return linkPath === route.path || isPapyrusView;
};

// Admin has a subnavigation
const isAdminLinkActive = (linkName: string): boolean => {
  return route.path.includes("/admin") && linkName !== ROUTES.ADMIN_LOGOUT.name;
};
const toggleMobileMenu = () => {
  isMobileMenuOpen.value = !isMobileMenuOpen.value;
};
</script>

<template>
  <div
    class="header-nav-container"
    :class="{
      'header-nav-public-links-with-admin': AdminApi.isLoggedIn(),
    }">
    <div class="header-nav-mobile">
      <i class="pi pi-align-justify" @click="toggleMobileMenu"></i>
    </div>
    <div
      class="header-nav-public-links"
      :class="{
        'header-nav-public-links-mobile': isMobileMenuOpen,
      }">
      <div class="header-nav-item" v-for="link in publicLinks" :key="link.name">
        <a
          :href="link.path"
          :class="{ active: isPublicLinkActive(link.path) }"
          @click.prevent="navigateTo(link.path)">
          <i :class="`pi pi-${link.iconName}`"></i>
          {{ link.name }}
        </a>
      </div>
    </div>
    <div class="header-nav-admin-links" v-if="AdminApi.isLoggedIn()">
      <div
        class="header-nav-item"
        v-for="adminLink in adminLinks"
        :key="adminLink.name">
        <a
          :href="adminLink.path"
          :class="{
            active: isAdminLinkActive(adminLink.name),
          }"
          @click.prevent="navigateTo(adminLink.path)">
          <i :class="`pi pi-${adminLink.iconName}`"></i>
          {{ adminLink.name }}
        </a>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import "@/assets/variables.scss";
.header-nav-public-links {
  display: flex;
  align-items: center;
  margin-top: 2px;
}
.header-nav-admin-links {
  display: flex;
  margin-left: auto;
  padding-right: 16px;
  margin-top: 2px;
}
.header-nav-mobile {
  display: none;
}
.header-nav-public-links-mobile {
  display: none;
  flex-direction: column;
  gap: 16px;
  flex-direction: column;
  position: absolute;
  top: 50px;
  left: 0;
  right: 0;
  background-color: $archeion-white;
  padding: 10px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 99;
  user-select: none;
}

@mixin responsive-nav {
  .header-nav-public-links {
    display: none;
  }
  .header-nav-public-links-mobile {
    display: flex;
  }
  .header-nav-admin-links {
    display: none;
  }
  .header-nav-mobile {
    cursor: pointer;
    display: inline;
    margin-left: auto;
    margin-right: 16px;
  }
}
.header-nav-container {
  display: contents;
}
@media (max-width: $phone-breakpoint) {
  @include responsive-nav;
}
// if admin links are displayed, make some more space
@media (max-width: calc($phone-breakpoint + 200px)) {
  .header-nav-public-links-with-admin {
    @include responsive-nav;
  }
}

.header-nav-item {
  @include small-bold;
  color: $text-color;
  padding-left: 16px;
  user-select: none;

  a {
    text-decoration: none;
    color: $text-color;

    &:hover {
      color: $archeion-red;
      text-decoration: none;
      border-bottom: 1px solid $archeion-red;
    }
  }
  a.active {
    color: $archeion-red;
    border-bottom: 1px solid $archeion-red;
  }
}
</style>
