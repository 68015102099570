import { createApp } from "vue";
import PrimeVue from "primevue/config";
//@ts-expect-error wait for update
import PrimeOne from "primevue/themes/primeone";
import ToastService from "primevue/toastservice";
import Toast from "primevue/toast";
import { createPinia } from "pinia";

import App from "./App.vue";
import router from "@/router/router";
import { ArcheionPreset } from "./primevuePreset";

import "primeicons/primeicons.css";
import "@/assets/main.scss";

const app = createApp(App);
app.use(PrimeVue, {
  theme: {
    base: PrimeOne,
    preset: ArcheionPreset,
    options: {
      // deactivates darkMode for now, use "system" to activate
      darkModeSelector: ".archeion-dark",
    },
  },
});
app.use(ToastService);
app.use(createPinia());
app.use(router);
app.component("ArcheionToast", Toast);

app.mount("#app");
