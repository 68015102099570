import { Extension } from "@tiptap/core";
import type { EditorState } from "@tiptap/pm/state";
import { useAdminStore } from "@/stores/useAdminStore";
import { checkSelectedText } from "./TipTapAnnotationHelper";
import {
  ANNOTATION_CONTENT_IDENTIFIER,
  ANNOTATION_INTERNAL_IDENTIFIER,
  ANNOTATION_EXTENSION_NAME,
} from "@/models/papyrusText";

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    highlight: {
      setAnnotation: () => ReturnType;
      updateAnnotation: (
        uniqueId: string,
        annotationIdentifer: string,
      ) => ReturnType;
      deleteAnnotation: (uniqueId: string) => ReturnType;
    };
  }
}

export const TiptapAnnotation = Extension.create({
  name: ANNOTATION_EXTENSION_NAME,

  addCommands() {
    return {
      setAnnotation:
        () =>
        ({ state }: { state: EditorState }) => {
          const errors = checkSelectedText(state);
          if (errors.length > 0) {
            useAdminStore().data.message = {
              type: "error",
              content: errors.join("\n"),
            };
            return false;
          } else {
            if (useAdminStore().data.selectedTextType === "Original") {
              useAdminStore().data.dialog = {
                type: "add-apparatus",
                title: "Add Apparatus Annotation",
              };
            } else {
              useAdminStore().data.dialog = {
                type: "add-glossary",
                title: "Assign Glossary Annotation",
              };
            }

            return true;
          }
        },
      updateAnnotation:
        (uniqueId, annotationIdentifer) =>
        ({ state, dispatch }) => {
          const { tr, doc } = state;
          let transactionApplied = false;
          // TODO: refactor node mark traversing
          doc.descendants((node, pos) => {
            node.marks.forEach((mark) => {
              if (mark.attrs[ANNOTATION_INTERNAL_IDENTIFIER] === uniqueId) {
                const newAttrs = {
                  [ANNOTATION_CONTENT_IDENTIFIER]: annotationIdentifer,
                };
                const updatedAttrs = { ...mark.attrs, ...newAttrs };
                tr.removeMark(pos, pos + node.nodeSize, mark);
                tr.addMark(
                  pos,
                  pos + node.nodeSize,
                  state.schema.marks.highlightMark.create(updatedAttrs),
                );
                transactionApplied = true;
              }
            });
          });
          if (transactionApplied && dispatch) {
            dispatch(tr);
          }
          return transactionApplied;
        },
      deleteAnnotation:
        (uniqueId) =>
        ({ state, dispatch }) => {
          const { tr } = state;
          const { doc } = state;
          let transactionApplied = false;
          doc.descendants((node, pos) => {
            if (node.marks.length > 0) {
              node.marks.forEach((mark) => {
                if (mark.attrs[ANNOTATION_INTERNAL_IDENTIFIER] === uniqueId) {
                  tr.removeMark(pos, pos + node.nodeSize, mark);
                  transactionApplied = true;
                }
              });
            }
          });
          if (transactionApplied && dispatch) {
            dispatch(tr);
          }
          return transactionApplied;
        },
    };
  },

  addGlobalAttributes() {
    return [
      {
        types: ["textStyle"],
        attributes: {
          class: {
            default: null,
            renderHTML: (attributes) => {
              if (!attributes.class) {
                return {};
              }

              return {
                class: attributes.class,
              };
            },
          },
          // TODO: looks the same in HighlightMark.ts, needed?
          [ANNOTATION_CONTENT_IDENTIFIER]: {
            default: null,
            renderHTML: (attributes) => {
              if (!attributes[ANNOTATION_CONTENT_IDENTIFIER]) {
                return {};
              }
              return {
                [ANNOTATION_CONTENT_IDENTIFIER]:
                  attributes[ANNOTATION_CONTENT_IDENTIFIER],
              };
            },
          },
          // TODO: looks the same in HighlightMark.ts, needed?
          [ANNOTATION_INTERNAL_IDENTIFIER]: {
            default: null,
            renderHTML: (attributes) => {
              if (!attributes[ANNOTATION_INTERNAL_IDENTIFIER]) {
                return {};
              }
              return {
                [ANNOTATION_INTERNAL_IDENTIFIER]:
                  attributes[ANNOTATION_INTERNAL_IDENTIFIER],
              };
            },
          },
        },
      },
    ];
  },

  renderHTML({ HTMLAttributes }: { HTMLAttributes: Record<string, string> }) {
    return ["span", HTMLAttributes, 0];
  },
});
