<script setup lang="ts">
import PageLayout from "@/components/PageLayout.vue";
import Welcome from "@/views/Home/WelcomeText.vue";
import SearchByQuery from "@/views/Home/SearchByQuery.vue";
import SearchByIndex from "@/views/Home/SearchByIndex.vue";
import { useApiStore } from "@/stores/useApiStore";

const apiStore = useApiStore();
</script>

<template>
  <PageLayout :is-centered-content="true">
    <template #static-content>
      <div class="homepage-logo-container">
        <img
          class="home-logo-image"
          :class="{
            'home-logo-image-animated': apiStore.data.state === 'loading',
          }"
          src="@/assets/images/logo.svg"
          alt="Archeion Logo large" />
      </div>
      <h1 class="home-title-heading">
        Welcome to our online archive of papyri
      </h1>
    </template>
    <template #default>
      <Welcome />
      <div class="home-section">
        <h2 class="home-section-heading">Search</h2>
        <div class="home-subsection"><SearchByQuery /></div>
        <div class="home-subsection"><SearchByIndex /></div>
      </div>
      <br /><br /><br /><br />
    </template>
  </PageLayout>
</template>

<style lang="scss">
@import "@/assets/variables.scss";

.home-parallax-background {
  position: fixed;
  z-index: -1;
  width: 100%;
  height: 100%;
  background-image: url("@/assets/images/background.jpg");
  background-size: cover;
  background-repeat: repeat-y;
  background-attachment: fixed;
  background-position-y: 0px;
}
.content-container {
  padding: 12px 64px 12px 64px;
  background-color: rgba(255, 255, 255, $overlay-opacity);
  border: 1px solid $container-divider-color;
  border-width: 0 1px 1px 1px;
  border-radius: 16px;
}

.content-container-more-visible {
  background-color: rgba(255, 255, 255, $overlay-opacity-content);
}

@media (max-width: $phone-breakpoint) {
  .content-container {
    padding: 24px;
  }
}
.homepage-logo-container {
  width: 302px;
  // center logo
  margin: auto;
}
.home-logo-image {
  margin-left: -8px;
  margin-top: 2px;
}
.home-logo-image-animated {
  animation: logo-animation 2s infinite;
}

@keyframes logo-animation {
  0% {
    filter: brightness(100%);
  }
  50% {
    filter: brightness(110%);
  }
  100% {
    filter: brightness(100%);
  }
}

.home-title-heading {
  @include large-normal;
  color: $text-color;
  text-shadow: 1px 1px 0 $logo-color;
  // center
  text-align: center;
}
.home-title-text {
  @include medium-normal;
  color: $text-color;
  // center
  text-align: center;
}
.home-title-text-error {
  color: $archeion-red;
}
.home-section {
  padding-bottom: 24px;
  max-width: $text-max-width;
  // text-align: center;
}
.home-subsection {
  padding-bottom: 12px;
}
.home-section-heading {
  @include large-bold;
  color: $text-color;
  text-align: center;
}
</style>
