// manages all data fetching for the frontend

import { ref } from "vue";
import { defineStore } from "pinia";
import { DateTime } from "luxon";
import axios from "axios";
import type {
  Papyrus,
  EditorialContent,
  Annotations,
  StrapiPapyrus,
  StrapiResult,
  StrapiResultSingle,
  StrapiEditorialContent,
  StrapiPapyrusKeywords,
  StrapiAnnotations,
  StrapiLiteratureList,
} from "@/models/strapi";
import { ENDPOINTS } from "@/services/ApiEndpoints";
import { getBaseStrapiUrl } from "@/services/Helpers";

type ApiData = {
  state: "loading" | "ready" | "error";
  papyri: Papyrus[];
  keywords: string[];
  editorialContent: EditorialContent;
  annotations: Annotations;
  literatureList: string;
};

const BASE_URL = getBaseStrapiUrl();
const MIN_WAIT_TIME = 600; // in ms

export const useApiStore = defineStore("api", () => {
  const data = ref<ApiData>({
    state: "loading",
    papyri: [],
    keywords: [],
    editorialContent: {
      welcome_text: "",
      terms: [],
      about_archeion: [],
      team: [],
    },
    annotations: {},
    literatureList: "",
  });

  // Public methods
  // initialLoad: loads all strapi data for the entire application
  const initialLoad = async () => {
    if (data.value.state === "ready") {
      return false;
    }
    const minWait = new Promise((resolve) =>
      setTimeout(resolve, MIN_WAIT_TIME),
    );
    try {
      await Promise.all([
        fetchAllPapyri(),
        fetchAllKeywords(),
        fetchEditorialContent(),
        fetchAnnotations(),
        fetchLiteratureList(),
        minWait,
      ]);
      data.value.state = "ready";
    } catch (error) {
      console.error("Failed during initial load:", error);
      data.value.state = "error";
      throw Error;
    }
  };

  // Private methods
  const fetchAllPapyri = async () => {
    try {
      const response = await axios.get<StrapiResult<StrapiPapyrus>>(
        // populate: fetch all related data too
        `${BASE_URL}${ENDPOINTS.papyrus}?populate=*`,
      );

      // parse API data for frontend
      data.value.papyri = response.data.data.map((item) => {
        const parsedItem = {
          id: item.id,
          ...item.attributes,
        };

        parsedItem.keywords_flattened = parsedItem.papyrus_keywords.data
          .map((item) => item.attributes.Keyword)
          .join("; ");

        parsedItem.dateFromInteger = DateTime.fromObject({
          year: parsedItem.dateFrom.year,
          month: parsedItem.dateFrom.month,
          day: parsedItem.dateFrom.day,
        }).toMillis();

        parsedItem.content = parsedItem.content ? parsedItem.content : "";
        parsedItem.form = parsedItem.form ? parsedItem.form : "";

        return parsedItem;
      });
    } catch (error) {
      console.error("Failed to fetch papyri:", error);
      throw Error;
    }
  };

  const fetchAllKeywords = async () => {
    try {
      const response = await axios.get<StrapiResult<StrapiPapyrusKeywords>>(
        `${BASE_URL}${ENDPOINTS.papyrusKeywords}`,
      );
      data.value.keywords = response.data.data.map(
        (item) => item.attributes.Keyword,
      );
    } catch (error) {
      console.error("Failed to fetch keywords:", error);
      throw Error;
    }
  };

  const fetchEditorialContent = async () => {
    try {
      const response = await axios.get<
        StrapiResultSingle<StrapiEditorialContent>
      >(`${BASE_URL}${ENDPOINTS.editorialContent}`);

      data.value.editorialContent.welcome_text =
        response.data.data.attributes.welcome_text;
      data.value.editorialContent.terms = response.data.data.attributes.terms;
      data.value.editorialContent.about_archeion =
        response.data.data.attributes.about_archeion;
      data.value.editorialContent.team = response.data.data.attributes.team;
    } catch (error) {
      console.error("Failed to fetch editorial-content:", error);
      throw Error;
    }
  };
  const fetchAnnotations = async () => {
    try {
      const response = await axios.get<StrapiResult<StrapiAnnotations>>(
        `${BASE_URL}${ENDPOINTS.annotation}`,
      );
      data.value.annotations = response.data.data.reduce((acc, item) => {
        acc[item.id] = {
          content: item.attributes.Content,
          type: item.attributes.Type,
          title: item.attributes.Title,
          description: item.attributes.Description,
        };
        return acc;
      }, {} as Annotations);
    } catch (error) {
      console.error("Failed to fetch papyrus-annotations:", error);
      throw Error;
    }
  };

  const fetchLiteratureList = async () => {
    try {
      const response = await axios.get<
        StrapiResultSingle<StrapiLiteratureList>
      >(`${BASE_URL}${ENDPOINTS.literatureList}`);
      data.value.literatureList = response.data.data.attributes.list;
    } catch (error) {
      console.error("Failed to fetch literatureList:", error);
      throw Error;
    }
  };

  return {
    data,
    initialLoad,
  };
});
