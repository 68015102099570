<script setup lang="ts">
import { ref } from "vue";
import { useRouter } from "vue-router";
import Button from "primevue/button";
import { ROUTES } from "@/models/routes";
import SearchField from "@/components/SearchField.vue";
const router = useRouter();
const searchQuery = ref("");

const onClickSearch = () => {
  router.push(`${ROUTES.SEARCH.path}?q=${searchQuery.value}`);
};
const onKeyDown = (event: KeyboardEvent) => {
  if (event.key === "Enter") {
    onClickSearch();
  }
};
</script>

<template>
  <div class="query-search-container">
    <p>Find papyri by keyword, title, content, form or editions.</p>

    <SearchField
      placeholder="Type keyword, title, ..."
      name="query-search"
      :inputValue="searchQuery"
      :onKeyDown="onKeyDown"
      @updateValue="searchQuery = $event" />

    <div class="query-search-container-button">
      <Button
        :pt="{ root: 'archeion-rounded-button' }"
        label="Search"
        @click="onClickSearch">
      </Button>
    </div>
  </div>
</template>

<style lang="scss">
@import "@/assets/variables.scss";

.query-search-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  .archeion-rounded-button {
    @include archeion-rounded-button;
  }
  .archeion-search-field {
    @include small-normal;
    width: 250px;
    height: 48px;
  }
}

.query-search-icon-field {
  margin-left: 2px;
}
.query-search-container-button {
  margin-top: 16px;
}
</style>
