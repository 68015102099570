<script setup lang="ts">
import { inject, type ShallowRef } from "vue";
import Button from "primevue/button";
import Divider from "primevue/divider";
import type { Editor } from "@tiptap/vue-3";

import { useAdminStore } from "@/stores/useAdminStore";
import { removeMarks } from "./TipTap/Extensions/TipTapAnnotationHelper";

defineProps({
  isPapyrusLoading: Boolean,
  isTextSelected: Boolean,
  isCursorInsideMark: Boolean,
});
const adminStore = useAdminStore();
const editor = inject("editor") as ShallowRef<Editor | undefined>;

const addAnnotation = () => {
  if (editor) {
    editor.value?.chain().focus().setAnnotation().run();
  }
};

const insertLineBreak = () => {
  useAdminStore().data.dialog = {
    type: "insert-linebreak",
    title: "Insert line number",
  };
};

const insertHandhshift = () => {
  useAdminStore().data.dialog = {
    type: "insert-handshift",
    title: "Insert handshift",
  };
};
const onRemoveAnnotationMark = () => {
  if (editor.value) {
    removeMarks(editor.value);
  }
};
const onUndo = () => {
  if (editor.value) {
    editor.value.commands.undo();
  }
};
const onRedo = () => {
  if (editor.value) {
    editor.value.commands.redo();
  }
};
</script>
<template>
  <div class="editor-toolbar-top">
    <Button
      :disabled="isPapyrusLoading || isTextSelected || isCursorInsideMark"
      label="Line number"
      title="Add line number"
      icon="pi pi-bookmark"
      severity="info"
      class="editor-toolbar-button-line-number"
      @click="insertLineBreak"></Button>
    <Button
      :disabled="isPapyrusLoading || isTextSelected || isCursorInsideMark"
      label="Handshift"
      icon="pi pi-user-edit"
      severity="info"
      class="editor-toolbar-button-handshift"
      @click="insertHandhshift"></Button>
    <Divider layout="vertical" />
    <Button
      :disabled="isPapyrusLoading || !isTextSelected"
      :label="
        adminStore.data.selectedTextType === 'Original'
          ? 'Annotate'
          : 'Annotate'
      "
      icon="pi pi-comment"
      title="Add a new apparatus, add a new glossary annotation or assign an existing glossary annotation to the selected text."
      severity="help"
      class="editor-toolbar-button-annotate"
      @click="addAnnotation"></Button>

    <Button
      :disabled="isPapyrusLoading || !isTextSelected"
      class="editor-toolbar-button-unmark"
      label="Unmark"
      title="Removes annotations, line numbers and handshifts from the selected text."
      icon="pi pi-trash"
      severity="help"
      @click="onRemoveAnnotationMark"></Button>
    <Divider layout="vertical" />
    <Button
      :disabled="isPapyrusLoading || (editor && !editor.can().undo())"
      class="editor-toolbar-button-undo"
      label="Undo"
      title="Undo last change"
      icon="pi pi-step-backward"
      severity="help"
      @click="onUndo"></Button>
    <Button
      :disabled="isPapyrusLoading || (editor && !editor.can().redo())"
      class="editor-toolbar-button-redo"
      label="Redo"
      title="Redo last change"
      icon="pi pi-step-forward"
      severity="help"
      @click="onRedo"></Button>
  </div>
</template>

<style lang="scss">
@import "@/assets/variables.scss";

.editor-toolbar-top {
  padding: 8px;
  border: 1px solid $container-divider-color;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;

  .editor-toolbar-button-line-number {
    @include archeion-editor-toolbar-button;
  }
  .editor-toolbar-button-handshift {
    @include archeion-editor-toolbar-button;
    @include archeion-orange-button;
  }
  .editor-toolbar-button-annotate {
    @include archeion-editor-toolbar-button;
  }
  .editor-toolbar-button-unmark {
    @include archeion-editor-toolbar-button;
    @include archeion-black-button;
  }
  .editor-toolbar-button-redo {
    @include archeion-editor-toolbar-button;
    @include archeion-black-button;
  }
  .editor-toolbar-button-undo {
    @include archeion-editor-toolbar-button;
    @include archeion-black-button;
  }
}
</style>
